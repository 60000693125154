//Configure bundle

.data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    &__information {
        display: flex;
        align-items: center;
    }
    &__select {
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
    }
    &__desc {
        display: flex;
        flex-direction: column;
        justify-self: center;
        &__title {
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            color: $heading;
            margin-bottom: 5px;
            .btn-text.link {
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                padding: 0;
            }
        }
        &__description {
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            color: $text;
        }
    }
    &__control {
        display: flex;
        align-items: center;
        .ds-btn.outline {
            margin: 0 30px 0 15px;
        }
    }
    .accordion-button:not(.collapsed) {
        color: $active;
        background-color: transparent;
        box-shadow: none;
    }
}

.parameters-pane,
.authentication-pane {
    .authentication {
        padding: 15px;
        form {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
        .column {
            width: 400px;
            max-width: 400px;
            padding: 0 15px 0 0;

            &:last-child {
                padding: 0 0 0 15px;
            }
            .button {
                padding-top: 24px;
            }
        }
    }
    .parameter {
        display: flex;
        justify-content: space-between;
        background: $page-bg-dark;
        padding: 15px;
        border-top: solid 1px $border-primary;
        border-bottom: solid 1px $border-primary;
        &__title {
            display: flex;
            align-items: center;
            color: $heading;
            font-weight: 500;
        }
        &__controls {
            display: flex;
            button {
                margin: 0 5px;
            }
        }
        button {
            background: $white;
            &:hover {
                background: $hover;
            }
            &:focus {
                background: $hover;
            }
            &.delete {
                &:hover {
                    background: $required;
                }
                &:focus {
                    background: $required;
                }
            }
        }
    }
    .add-parameters {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button a.ds-btn.outline {
            color: $heading;
            font-weight: 500;
        }
    }
}

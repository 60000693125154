$font-size: 3.5rem;
$button-shadow: 0px 3px 6px rgba(0, 62, 97, 0.1);
$dropdown-shadow: 0px 0px 15px rgba(0, 62, 97, 0.2);
$tiles-shadow: 0px 5px 15px rgba(0, 62, 97, 0.1);
$tiles-shadow-hover: 0px 10px 25px rgba(0, 62, 97, 0.4);
$avatar-shadow: 0px 5px 16px rgba(0, 62, 97, 0.2);
$border-radius: 10px;
$control-button: 0px 5px 20px rgba(0, 58, 111, 0.25);
$panel: 0px 0px 40px rgba(0, 62, 97, 0.3);


@mixin blurredBG {
    background: rgba(229, 228, 228, 0.7) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid white !important;
}

@mixin flex-col-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin flex-row-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

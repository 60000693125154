
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");
@import url("https://unpkg.com/swiper/swiper-bundle.min.css");
@import url("https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.css");

@import "../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css";

@import "./color";
@import "./variables";
@import "./typography";
@import "./buttons";
@import "./forms";

@import "./swiper";
@import "./layout";
@import "./nav";
@import "_configure-bundle.scss";
@import "_bundle-config.scss";
@import "./tileflip";
@import "_tabs.scss";
@import "_modals.scss";
@import "_accordion.scss";
@import "_bundle-info.scss";
@import "_account-settings.scss";
@import "_account-setup.scss";
@import "_vanilla-message.scss";
@import "_product-wizard.scss";
@import "_requests.scss";
@import "./_record-row.scss";
@import "./datepicker-overrides.scss";
@import "./_dashboard.scss";
@import "./_landing-page.scss";


@import "./globals";

.btn.dropdown-toggle.show {
    border-color: transparent;
}

.modal-footer .ds-btn {
    margin: 4px;
}
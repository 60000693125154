﻿
.tile-layout {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    overflow: hidden;
    border-left: none;
    border-right: none;
    border-top: solid 1px rgba(0, 59, 105, 0.2);

    &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-top: none;
    }

    
}

.tile.has-edit {
    &.participation-status .tile__bundle__status {
       display: none;
    }


    .tile__bundle__status {
        right: 50px;
        position: absolute;
        z-index: 0;
        top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        min-width: 30px;
        border-radius: 15px;
        background: $button-bg;

        .private-lock {
            margin: 0;
            display: flex;
            padding: 0 2px;

            .icon {
                color: $white;

                &:first-child {
                    padding: 0 5px 0 5px;
                }

                &:last-child {
                    padding: 0 5px 0 5px;
                  
                }
            }
        }
    }

    .tile__configure {
        display: inline-flex;
        position: absolute;
        right: 10px;
        top: 10px;
    }



    &.tile.participation-status .request-status {
        display: none;
    }
}

    .tile.participation-status {
     
        .request-status {
            position: absolute;
            font-size: 12px;
            line-height: 12px;
            top: 12px;
            right: 17px;
            display: flex;
            align-items: center;
            padding: 4px 10px 4px 4px;
            background: $page-bg-dark;
            border-radius: 20px;

            &__icon {
                background: $button-bg;
                border-radius: 50%;
                color: $white;
                font-size: 16px;
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;

                &.accepted {
                    background: $green;
                }
            }

            &__title {
                margin-right: 5px;
            }

            &__date {
                color: $heading;
                font-weight: 700;
            }
        }
    }




.tile {
    width: 100%;
    cursor: pointer;
    background-color: $white;
    padding: 0;
    position: relative;
    overflow: hidden;

    &__bundle__status, .request-status {
        display: none;
    }


    &__label {
        width: 16px;
        display: flex;
        align-items: center;

        .pill {
            height: 100%;
            width: 16px;
            padding: 0;

            span {
                transform: rotate(-90deg);
            }
        }
    }



    &.list-view {
        height: auto;
        box-shadow: none;
        padding: 0;
        border-radius: 0;
        border: none;

        &:hover {
            box-shadow: none;
            cursor: default;
        }
    }

    &:hover {
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
        color: $text;
        display: block;
        opacity: 1;
        background: $white;


        .swipe-item {
            padding: 5px;
            background: $white;
            position: relative;
            z-index: 1;
            display: flex;
        }

        .reveal-right {
            position: absolute;
            top: 0;
            height: 100%;
            width: 220px;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 8px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .reveal-right {
            border-radius: 0 10px 10px 0;
            right: 0;
            text-align: left;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 15px;
            justify-content: center;

            .users {
                display: flex;
                align-items: center;
                font-size: 14px;
                height: 40px;
                color: $heading;

                &__number {
                    font-weight: 700;
                    color: $heading;
                    background: #d3d9df;
                    padding: 3px 10px;
                    border-radius: 30px;
                    margin-right: 8px;
                    width: 45px;
                    text-align: center;
                }
            }
        }


        .bundle__label {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    
    &__middle {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    &__left {
        display: flex;
        width:40%;
    }

    &__right {
        display: flex;
        justify-content: flex-end;
        flex: 1;
    }

    &__share {
        margin-right: 8px;
        align-content: center;;
    }

    &__configure {
        display: none;

        button {
            font-size: 16px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white;
            border-radius: 50%;

            .icon {
                color: $icons;
                position: relative;
                top: 3px;
            }

            &.show {
                box-shadow: $button-shadow;
            }
        }

        .dropdown-menu {
            box-shadow: $dropdown-shadow;
            background: $page-bg-light;
            border: solid 1px $white;
            border-radius: 15px;

            &.show {
                display: flex;
                justify-content: center;
                transform: translate3d(0px, 0px, 0px) !important;//transform: translate3d(-97px, 0px, 0px) !important;
                min-width: 8rem;

                li {
                    flex: 1;
                    display: flex;
                    justify-content: center;

                    a {
                        color: $app-primary01;

                        &:hover {
                            color: $emphasize;
                        }
                    }
                }
            }
        }

        .dropdown-toggle::after {
            content: "";
            display: none;
        }
    }





    &__busname, &__group {
        font-size: 12px;
        color: $heading;
        line-height: 18px;

        .icon {
            position: relative;
            top: 3px;
            font-size: 16px;
        }
    }

    &__body {
        padding: 10px 10px 5px 16px;
    }





    &__heading {
        display: flex;
        align-items: baseline;
    }

    &__title {
        font-weight: 700;
        font-size: 16px;
        color: $heading;
        margin: 0;
    }

    &__data-source {
        font-size: 14px;
        padding: 0 10px;
        font-weight: normal;
        position: relative;
        top: 2px;

        .icon {
            font-size: 24px;
            color: $heading;
        }

        .data-count {
            position: absolute;
            top: -10px;
            right: 0;
            font-size: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $active;
            color: $white;
        }
    }

    &__meta {
        display: flex;
        width: 100%;
        align-items: center;
        font-size: 12px;
        font-weight: 300;

        &__author {
            display: flex;
        }
    }



    &__status {
        white-space: nowrap;
        margin-right: 5px;
        color: $text;
        font-weight: 300;
    }

    &__name {
        padding-left: 5px;
        display: flex;
        color: $text;
        font-weight: 300;

        .profile-pic {
            width: 20px;
            height: 20px;
            position: relative;
            background: $white;
            font-size: 8px;
            top: 0;
            border: 1px solid $white;
            border-radius: 50%;
            box-shadow: 0px 5px 16px rgba(0, 62, 97, 0.2);
            margin-right: 5px;
        }

        .profile-pic-bundle {
            width: 20px;
            height: 20px;
            border: 1px solid $white;
            border-radius: 50%;
            box-shadow: 0px 5px 16px rgba(0, 62, 97, 0.2);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            font-size: 9px;
        }
    }

    &__author {
        color: $heading;
        font-weight: 500;
        margin-left: 3px;
    }


    &__footer {
        display: flex;
        align-items: flex-end;
        padding: 0 10px 5px;

        .button {
            flex: 1;
            padding-right: 5px;

            &:last-child {
                padding: 0 0 0 5px;
            }
        }
    }
}

    .page-container {
        visibility: hidden;
    }

    .page {
        cursor: pointer;
        position: absolute;
        background-color: white;
        position: fixed;
        z-index: 99;
        border-radius: 10px;
        padding: 30px;

        &.request {
            button.icon-btn {
                width: 30px;
                height: 30px;
                background: $white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: solid 1px $input-border;
                box-shadow: $button-shadow;
                position: absolute;
                top: -15px;
                right: -15px;

                .icon {
                    position: relative;
                    top: 2px;
                    font-size: 12px;
                }
            }
        }

        .wizard__status__complete {
            display: none;
        }


        @media (max-width: 500px) {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0;
        }

        @media (max-width: 1600px) {
            top: 10%;
            left: 10%;
            right: 10%;
            bottom: 10%;
        }

        @media (min-width: 1600.1px) {
            top: 10%;
            left: 15%;
            right: 15%;
            bottom: 10%;
        }

        .inner-content {
            width: 100%;
            height: 100%;
            background: $white;
            overflow-y: auto;
            position: relative;
            color: $text;
            opacity: 0;

            .controls {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 65px;
                display: flex;
                justify-content: center;
                align-items: center;

                .button {
                    padding: 0 5px;

                    button {
                        width: 150px;
                    }
                }
            }

            .tabs-container {
                margin-top: 0px;
            }
        }
    }

    .backdrop {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        visibility: hidden;
        background-color: $backdrop;
        transform: scale(0);
    }

.grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100% - 210px) !important;
    background: $white;
    border-radius: 10px;
    overflow: hidden;
    border: solid 1px rgba(0, 59, 105, 0.2);

    .tile__inner {
        background: $page-bg-dark;
    }
}


    .product-status {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__label {
            color: $white;
            font-size: 12px;
            font-weight: 500;
            padding: 0 5px;
            border-radius: 4px;

            &.published, &.pending, &.suspended {
                background: $active;
            }

            &.accepted {
                background: $app-primary01;
            }

            &.rejected, &.cancelled {
                background: $delete;
            }

            &.expired {
                background: $black;
            }
        }

        &__date {
            font-size: 12px;
            font-weight: 300;
        }
    }




    .private-lock {
        margin-left: 10px;

        .icon-eye-slash-bold-icon {
            padding: 0 5px;
        }

        .icon-filled-lock-icon {
            padding: 0 5px;
        }

        .icon {
            position: relative;
            top: 2px;
            font-size: 16px;
            color: $heading;
        }
    }

.tooltip-inner {
    max-width:100%;
    text-wrap:nowrap;
}

.share-profile-pic { 
    width: 40px;
    height: 40px;
    margin-right: 4px;
    border: solid 2px white;
    border-radius: 50%;
    box-shadow: 0px 5px 16px rgba(0, 62, 97, 0.2);
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    
}

.profile1 {
    background: #D5FFD1;
    color: #006704;
}
.profile2 {
    background: #FCF0C5;
    color: #A37E00;
}
.profile3 {
    background: #FFDEFC;
    color: #ED00DA;
}
.profile4 {
    background: #D7F4F3;
    color: #0D70C6;
}
.profile5 {
    background: #FEBDBD;
    color: #770808;
}
.profile6 {
    background: #D9D3FD;
    color: #7A00A5;
}
.profile7 {
    background: #D3E6FD;
    color: #1756F9;
}
.profile8 {
    background: #ADD8E6;
    color: #000000;
}

.match-search {
    border-color: #1d70fa !important;
}

.popover-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 5px;
    column-gap: 2px;
}

.popover-body div {
    text-align: center;
}
﻿



//Forms

form {

    .form-control {
        padding: initial;
        font-size: 14px;
        line-height: initial;
    }



    &#account .controls {
        margin-top: 27px;
    }

    width: 100%;

    section {
        padding: 30px 0;
        border-bottom: solid 1px $border-primary;

        &:first-child {
            padding: 15px 0 30px 0;
        }
    }

    input.btn {
        width: initial;
    }

    .delete {
        background: $delete;
    }

    .form-title {
        margin-bottom: 10px;
    }

    .form-item,
    label,
    input,
    textarea {
        width: 100%;
        color: $text;
        font-weight: 300;
    }

    .form-group {
        width: 100%;
        margin-bottom: 30px;
        position: relative;

        &.inline-input {
            position: relative;

            button.inline-btn {
                position: absolute;
                right: 5px;
                bottom: 6px;
                background: $emphasize;
                color: $white;
                border-radius: 4px;
                font-size: 16px;
                padding: 3px 10px;
                border: solid 1px transparent;
                outline: none;
            }
        }
    }

    span.form-required, .required {
        color: $required;
    }



    label {
        margin: 0;
        font-size: 14px;
        text-align: left;
        line-height: 18px;
    }

    input[type="date"],
    input[type="search"],
    input[type="url"],
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    textarea.form-control {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: $input;
        border: solid 1px $input-border;
        border-radius: 4px;
        color: $text;
        font-weight: 300;

        &:focus,
        &:focus-visible {
            border: solid 1px rgba($app-primary01, 0.4);
            outline: none;
            box-shadow: none;
        }
    }

    textarea.form-control {
        min-height: 120px;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .form-control.explorer-textarea {
        max-height:400px;
    }

    input[type="password"] {
        letter-spacing: 5px;
    }

    input {
        height: 54px;
    }

    input[type="search"] {
        background-image: url(../assets/images/icons/search-icon.svg);
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;
    }

    #login-button,
    #reset-password {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $app-primary01;
        border: none;
        margin-bottom: 20px;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $hover;
        }
    }

    #btnRegister {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $app-primary01;
        border: none;
        margin-bottom: 20px;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $hover;
        }
    }

    .checkbox {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        input[type="checkbox"] {
            width: initial;
            margin-right: 7px;
        }
    }

    .controls {
        display: flex;
        justify-content: space-between;

        .form-group {
            width: initial;
        }
    }

    .forgot-password {
        font-size: 14px;
        font-weight: 500;
        text-align: right;
    }
}
    //checkbox
    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;

        input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }




        input:checked:focus ~ .control_indicator,
        &:hover input:not([disabled]):checked ~ .control_indicator {
            background: $active;
        }

        input:disabled ~ .control_indicator {
            background: #e6e6e6;
            border: solid 1px #cccccc;
            opacity: 0.6;
            pointer-events: none;
        }

        input:checked ~ .control_indicator:after {
            display: block;
        }
    }

    .control_indicator {
        position: absolute;
        top: 1px;
        left: 0;
        height: 20px;
        width: 20px;
        background: $white;
        border: 1px solid $border-primary;
        border-radius: 4px;
    }
    /* .control:hover input~.control_indicator,
        .control input:focus~.control_indicator {
            background: #cccccc;
        } */

    .control_indicator:after {
        box-sizing: unset;
        content: "";
        position: absolute;
        display: none;
    }

    .control-checkbox .control_indicator:after {
        left: 7px;
        top: 3px;
        width: 3px;
        height: 8px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }

    .control-checkbox .control_indicator::before {
        content: "";
        display: block;
        position: absolute;
        left: -7px;
        top: -5px;
        width: 4.5rem;
        height: 4.5rem;
        margin-left: -1.3rem;
        margin-top: -1.3rem;
        background: #2aa1c0;
        border-radius: 3rem;
        opacity: 0.6;
        z-index: 99999;
        transform: scale(0);
    }
    //@keyframes s-ripple {
    //  0% {
    //    transform: scale(0);
    //  }
    //  20% {
    //    transform: scale(1);
    //  }
    //  100% {
    //    opacity: 0;
    //    transform: scale(1);
    //  }
    //}

    @keyframes s-ripple-dup {
        0% {
            transform: scale(0);
        }

        30% {
            transform: scale(1);
        }

        60% {
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(1);
        }
    }

    .control-checkbox input + .control_indicator::before {
        animation: s-ripple 250ms ease-out;
    }

    .control-checkbox input:checked + .control_indicator::before {
        animation-name: s-ripple-dup;
    }

    input:-internal-autofill-selected {
        color: $text !important;
    }

    .form-container {
        margin: 50px 0 50px 0;
        background: $white;
        padding: 30px 0;
        border-radius: 10px;
        min-height: 421px;
        position: relative;

        &.home {
            margin: 0;
            box-shadow: none;
            border: solid 1px $border-primary;

            label.checkbox__label {
                font-size: 14px;
            }
        }
    }


    .register {

        .logo {
            position: absolute;
            top: 15px;
            left: 15px;
            margin: 0;

            a {
                cursor: pointer;
            }
        }

        .form-container {
            margin: 0;
            background: $white;
            padding: 30px 0 30px 0;
            border-radius: 10px;
            min-height: 421px;
            position: relative;
            box-shadow: none;
            width: 400px;
            max-width: 400px;

            .register__form {
                opacity: 0;
            }

            &.is-complete, &.is-validating, &.is-duplicate {
                .form-elements {
                    display: none;
                }

                .logo {
                    position: absolute;
                    width: 100%;
                    bottom: 30px;
                }
            }

            &.is-complete .message__thankyou,
            &.is-validating .message__validating,
            &.is-duplicate .message__duplicate {
                display: flex;
            }

            .message__thankyou,
            .message__validating,
            .message__duplicate {
                display: none;
                flex-direction: column;
                position: absolute;
                width: 100%;
                top: 30px;
                text-align: center;
                padding: 0 30px;
                height: 308px;

                .form__title {
                    margin: 10px 0;
                    text-align: center;

                    h4 {
                        text-align: center;
                        margin-bottom: 0;
                    }
                }

                .message__image {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                a {
                    display: inline-block;
                }
            }
        }
    }

    form .form-group {
        margin-bottom: 15px;

        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="tel"] {
            height: 44px;
            padding-right: 50px;
        }
    }



    #btnRegister {
        height: 44px;
        margin-bottom: 5px;
        margin: 20px 0 5px 0;
    }

    .existing-user {
        display: flex;
        font-size: 14px;

        a {
            font-weight: 500;
            margin-left: 5px;
        }
    }

    .logo {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .view-password {
        position: absolute;
        right: 10px;
        width: 30px;
        height: 30px;
        top: 32px;

        &.fa-eye {
            background: url(../assets/images/icons/eye-icon.svg) no-repeat center center;
            background-size: 14px;
        }

        &.fa-eye-slash {
            background: url(../assets/images/icons/eye-slash-icon.svg) no-repeat center center;
            background-size: 14px;
        }
    }

    #lottie_thankyou,
    #lottie_validating,
    #lottie_duplicate {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #lottie_thankyou {
        max-width: 100px;
        height: auto;
    }

    #lottie_duplicate {
        max-width: 80px;
        height: auto;
    }

    .form-label {
        margin-bottom: 0;
    }

    .the-count {
        font-size: 14px;
        color: $text;
    }
    //Toggle switch
    .switch {
        position: relative;
        display: inline-block;
        width: 25px;
        height: 10px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                left: -11px;
                bottom: -5px;
                border: solid 1px $input-border;
                box-shadow: $button-shadow;
                background-color: $white;
                border-radius: 50%;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }
        }
    }

    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .bg-light {
        background: #eef0f4;
    }

    .choices__list--dropdown .choices__item--selectable {
        padding-right: 1rem;
    }

    .choices__list--single {
        padding: 0;
    }

    .card {
        transform: translateY(-50%);
    }

    .choices[data-type*="select-one"]:after {
        right: 1.5rem;
    }

    .shadow {
        box-shadow: 0.3rem 0.3rem 1rem rgba(178, 200, 244, 0.23);
    }

    a {
        text-decoration: none;
        color: inherit;
        transition: all 0.3s;
    }
    /* custom select menu*/

    .form-group.dropdown {
        .custom-select-wrapper {
            position: relative;
            user-select: none;
            width: 100%;

            .custom-select {
                position: relative;
                display: flex;
                flex-direction: column;

                &.open .custom-options {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }

                &__trigger {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0 10px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $text;
                    height: 18px;
                    line-height: 18px;
                    background: $input;
                    cursor: pointer;
                    border: solid 1px $input-border;
                    height: 44px;

                    .arrow {
                        position: relative;
                        height: 15px;
                        width: 15px;
                        transform: scale(0.4);
                    }

                    .arrow::before,
                    .arrow::after {
                        content: "";
                        position: absolute;
                        bottom: 0px;
                        width: 0.15rem;
                        height: 100%;
                        transition: all 0.5s;
                    }

                    .arrow::before {
                        left: -5px;
                        transform: rotate(45deg);
                        background-color: #394a6d;
                    }

                    .arrow::after {
                        left: 5px;
                        transform: rotate(-45deg);
                        background-color: #394a6d;
                    }

                    .open .arrow::before {
                        left: -5px;
                        transform: rotate(-45deg);
                    }

                    .open .arrow::after {
                        left: 5px;
                        transform: rotate(45deg);
                    }

                    span {
                        margin-right: 10px;
                        color: $text;
                        font-weight: 500;

                        &::before {
                            content: "";
                            margin-right: 5px;
                            color: #777777;
                            font-weight: 500;
                        }
                    }
                }

                .custom-options {
                    position: absolute;
                    display: block;
                    top: 43px;
                    left: 0;
                    width: 100%;
                    padding: 15px;
                    border: 1px solid $input-border;
                    background: #fff;
                    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
                    transition: all 0.5s;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    z-index: 2;

                    .custom-option {
                        position: relative;
                        display: block;
                        padding: 5px;
                        font-size: 14px;
                        font-weight: 300;
                        color: #777777;
                        line-height: 26px;
                        cursor: pointer;
                        transition: all 0.5s;

                        &:hover {
                            cursor: pointer;
                            background-color: #ffffff;
                        }

                        &.selected {
                            color: rgba(119, 119, 119, 0.6);
                        }
                    }
                }
            }
        }
    }

    .date-range {
        width: 200px;
        max-width: 200px;
    }

    #ToDate,
    #FromDate {
        border-radius: 4px;
    }

    .range {
        position: relative;
        width: 50px;
        height: 22px;
        top: 24px;

        .line {
            width: 100%;
            height: 1px;
            background: $border-primary;
            position: absolute;
            top: 22px;

            &::before,
            &::after {
                content: "";
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: $emphasize;
                position: absolute;
                top: -1px;
                z-index: 1;
            }

            &::before {
                left: -1px;
            }

            &::after {
                right: -2px;
            }
        }
    }

    .form-group.calendar {
        display: flex;
    }

    .input-daterange {
        display: flex;
        flex-direction: column;
    }

    .calendar-icon {
        position: relative;

        &:after {
            content: "";
            background-image: url(../assets/images/icons/calendar-icon.svg);
            background-repeat: no-repeat;
            background-size: 12px;
            display: inline-block;
            width: 12px;
            height: 12px;
            position: absolute;
            right: 10px;
            top: 14px;
            z-index: 10;
            pointer-events: none;
        }
    }


    .form-control.is-valid, .was-validated .form-control:valid {
        border-color: #198754;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url(../assets/images/icons/valid-green-tick.svg);
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        z-index: 99;
    }


    .input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
        z-index: 99;
    }

    .controls {
        margin-top: 50px;

        &.single {
            justify-content: flex-end;
            display: flex;
        }

        &.full {
            display: flex;
            justify-content: center;

            .button {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }



    .form-group {

        .dropdown {
            position: relative;
            width: 100%;
            display: flex;
            border-radius: 4px;
            transition: all .5s ease;
            position: relative;
            font-size: 14px;
            text-align: left;
            background-color: $input;
            border: solid 1px $input-border;
            border-radius: 4px;
            color: $text;
            font-weight: 300;
            align-items: center;

            &::after {
                position: absolute;
                right: 10px;
                display: inline-block;
                vertical-align: 0.255em;
                content: "";
                border-top: 0.3em solid #121212;
                border-right: 0.25em solid transparent;
                border-bottom: 0;
                border-left: 0.25em solid transparent;
            }

            &:focus-visible {
                border: solid 1px rgba($app-primary01, 0.4);
                outline: none;
                box-shadow: none;
            }

            &:active {
                background-color: $white;
                border-radius: 4px;

                &:hover {
                    background-color: $input-border;
                }
            }

            .select {
                cursor: pointer;
                display: block;
                padding: 10px;
            }

            .dropdown-menu {
                position: absolute;
                background-color: $white;
                width: 100%;
                left: 0;
                margin-top: 1px;
                border-radius: 4px;
                border: solid 1px $input-border;
                overflow: hidden;
                display: none;
                max-height: 154px;
                overflow-y: auto;
                z-index: 9;
                box-shadow: $dropdown-shadow;
                padding: 0;
                list-style: none;

                li {
                    padding: 10px;
                    transition: all .2s ease-in-out;
                    cursor: pointer;

                    &:hover, &:active {
                        background-color: $input-border;
                    }
                }
            }
        }

        .dropdown-action{
            width:100%;
        }
    }







    .checkbox {


        &__input {
            position: absolute;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
            opacity: 0;
        }

        &__label {
            font-size: 16px;
            color: $text;
            position: relative;
            cursor: pointer;
            line-height: 24px;
            padding-top: 2px;
            padding-bottom: 2px;
            padding-left: 32px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                margin: 3px;
                background: $white;
                border: solid 1px $border-primary;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-border-radius: 4px;
                border-radius: 4px;
            }
        }
    }




    .checkbox__input {

        &:checked ~ .checkbox__label:before {
            background-image: url(../assets/images/radio-tick.svg);
            background-color: $emphasize;
            border-color: $emphasize;
            -webkit-mask-image: url(../assets/images/radio-tick-mask.svg);
            mask-image: url(../assets/images/radio-tick-mask.svg);
        }

        &:disabled ~ .checkbox__label {
            color: rgba(0, 0, 0, 0.38);

            &:before {
                border-color: rgba(0, 0, 0, 0.26);
            }
        }

        &:checked:disabled ~ .checkbox__label:before {
            background-color: rgba(0, 0, 0, 0.26);
            background-clip: padding-box;
        }

        ~ .checkbox__label:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            -webkit-border-radius: 50%;
            border-radius: 50%;
        }

        &:focus ~ .checkbox__label:after {
            -webkit-animation: click-wave .5s;
            animation: click-wave .5s;
        }

        &:checked ~ .checkbox__label:after {
            background-color: $emphasize;
        }

        &:not(:checked) ~ .checkbox__label:after {
            background-color: $black;
        }
    }

    @-webkit-keyframes click-wave {
        0% {
            width: 24px;
            height: 24px;
            opacity: 0.5;
        }

        100% {
            width: 48px;
            height: 48px;
            margin-left: -15px;
            margin-top: -15px;
            opacity: 0.0;
        }
    }

    @keyframes click-wave {
        0% {
            width: 24px;
            height: 24px;
            opacity: 0.5;
        }

        100% {
            width: 48px;
            height: 48px;
            margin-left: -15px;
            margin-top: -15px;
            opacity: 0.0;
        }
    }
    //upload document


    .upload-files-container {
        background-color: $white;
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .drag-file-area {
        border: 2px dashed $page-bg-dark;
        border-radius: 10px;
        margin: 10px 0 10px;
        padding: 10px 50px;
        width: 100%;
        text-align: center;
    }

    .drag-file-area .upload-icon {
        font-size: 24px;
        color: $emphasize;

        .icon-check-circle-icon {
            font-size: 50px;
        }
    }


    .drag-file-area h3 {
        font-size: 18px;
        margin: 10px 0;
    }

    .drag-file-area label {
        font-size: 16px;
        text-align: left;
        line-height: 22px;
        height: 35px;
        display: flex;
        justify-content: center;

        .drag {
            position: relative;
            top: 2px;
            left: 0;
        }

        .browse-files {
            padding-left: 10px;
            position: relative;
        }
    }

    .drag-file-area label .label__container {
        display: flex;
    }

    .drag-file-area label .browse-files-text {
        color: $emphasize;
        font-weight: bolder;
        cursor: pointer;
    }

    .browse-files span {
        position: relative;
        top: 2px !important;
        left: 0px !important;
    }

    .default-file-input {
        opacity: 0;
        height: 30px;
        position: absolute;
        top: 0;
    }

    .cannot-upload-message {
        background: $red;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 5px 0;
        padding: 6px 0px 6px 15px;
        border-radius: 4px;
        color: $white;
        display: none;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .cannot-upload-message span, .upload-button-icon {
        padding-right: 10px;
    }

    .cannot-upload-message span:last-child {
        padding-left: 8px;
        cursor: pointer;
        font-size: 22px;
        transform: rotate(45deg);
        position: relative;
        top: 1px;
    }

    .file-block {
        color: $white;
        background-color: $text;
        transition: all 1s;
        width: 390px;
        position: relative;
        display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 15px;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;

        .icon {
            font-size: 22px;
        }
    }

    .file-info {
        display: flex;
        align-items: center;
        font-size: 15px;
    }

    .file-icon {
        margin-right: 10px;
    }

    .file-name, .file-size {
        padding: 0 3px;
    }

    .remove-file-icon {
        cursor: pointer;
    }

    .progress-bar {
        display: flex;
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 5px;
        border-radius: 0;
        background-color: #4BB543;
    }

    .upload-button {
        background-color: $emphasize;
        color: $white;
        display: flex;
        align-items: center;
        font-size: 16px;
        border: none;
        border-radius: 20px;
        margin: 5px;
        padding: 7.5px 30px;
        cursor: pointer;
    }


    .radio {
        position: relative;
        cursor: pointer;
        line-height: 15px;
        font-size: 14px;

        .label {
            position: relative;
            float: left;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            background: $white;
            border: 1px solid $border-primary;
            border-radius: 100%;
            -webkit-tap-highlight-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: $emphasize;
                transform: scale(0);
                transition: all 0.2s ease;
                opacity: 0.08;
                pointer-events: none;
            }
        }

        &:hover .label:after {
            transform: scale(3.6);
        }

        input[type="radio"]:checked + .label {
            border-color: $emphasize;
        }

        input[type="radio"]:checked + .label:after {
            transform: scale(1);
            transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
            opacity: 1;
        }

        .hidden {
            display: none;
        }
    }




    #createOrganisationForm {
        width: 100%;

        #organisationForm {
            border: 1px solid $border-primary;
            margin: 0 0 20px;
            padding: 15px;
            background: $white;
            border-radius: 10px;



            .controls.single {
                justify-content: center;
                display: flex;
                margin-top: 20px;

                .ds-btn {
                    width: 140px;
                    margin-left: 15px;
                }
            }
        }
    }





  
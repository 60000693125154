﻿//Globals


html,
body {
    height: 100%;
    font-family: "Roboto", sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
    color: $text;
    line-height: 1.5rem;
    font-size: 16px;
    font-weight: 300;
    background-color: $page-bg-light;

    .success {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: transparent;
        z-index: 99;
        @include flex-row-centered;

        &__inner {
            position: relative;
            height: 340px;
            width: 500px;
            border-radius: 20px;
            @include flex-col-centered;
            @include blurredBG;
        }

        &__icon {
            width: 50px;
            height: 50px;
            font-size: 34px;
            border-radius: 50%;
            @include flex-row-centered;
            color: $green;
            border: solid $green 3px;
        }

        &__title {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: $heading;
            line-height: 32px;
            padding: 20px 0;
        }

        &__message {
            margin-top: 20px;

            p {
                font-size: large;
                font-weight: 400;
                color: black;
                margin-right: 20px;
                margin-left: 20px;
                margin-bottom: 40px;
                text-align: center;
            }

            .controls {
                margin-top: 0;
                @include flex-row-centered;

                .button {
                    margin: 0 5px;
                }
            }
        }
    }.import-template{
        position: fixed;
        background: rgba(0, 0, 0, 0.6);
    }
}

input::-ms-reveal,
input::-ms-clear {
    display:none !important;
}

body.noscroll {
    overflow: hidden;
}


.notification-alert {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;

    &.overlay {
        background: rgba(0, 0, 0, 0.6);
        display: none;
  


        &.is-active {
            display: block;

            .notification-alert__panel {
                //transform: translateX(0px);
            }
        }
    }

    &__panel {
        width: 350px;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        //transform: translateX(350px);
        z-index: 10;
        background: $page-bg-light;
        box-shadow: $panel;
        //transition: 1s transform 0.3s ease-in-out;

        &__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            border-bottom: solid 1px $border-primary;
        }

        &__title {
            font-size: 24px;
            font-weight: 500;
            color: $heading;
        }
    }
}



    .arrow-up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $white;
    }

    .brand {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__logo {
            margin-top: 10px;
            display: flex;
            padding: 0 30px;
            justify-content: center;

            img {
                max-width: 100%;
            }
        }
    }

    .brand .app__logo {
        margin-left: 35px;
        max-width: 50px;
    }

    a {
        color: $emphasize;
        text-decoration: none;
        display: block;

        &:hover {
            text-decoration: none;
            outline: none;
        }

        &:focus {
            background: transparent;
            color: $active;
            border: none;
            text-decoration: none;
        }
    }

    .link {
        cursor: pointer;
        position: relative;
        white-space: nowrap;
        text-decoration: none;
        display: inline-block;
    }

    .link::before,
    .link::after {
        position: absolute;
        width: 100%;
        height: 1px;
        background: currentColor;
        top: 90%;
        left: 0;
        pointer-events: none;
    }

    .link::before {
        content: "";
        /* show by default */
    }

    .link::before {
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s;
    }

    .link:hover::before {
        transform-origin: 0% 50%;
        transform: scale3d(1, 1, 1);
    }

    .text-align-right {
        text-align: right;
    }

    main {
        position: relative;
        display: flex;
        flex-direction: column;
        top: 80px;
        left: 0;
        height: calc(100% - 80px);
        overflow: hidden;
        width: 100%;
        transition: all 0.3s ease-in-out;
    }

    .disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
    }





    footer {
        background: $footer-bg;

        &.home {
            background-color: rgba(255,255,255, 0.5);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }

    .footer {
        &__terms {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: $heading;
            padding: 10px 0;
            font-size: 12px;
            font-weight: 500;

            @media (max-width: 567px) {
                font-size: 14px;
                flex-direction: column;
            }

            .pipe {
                @media (max-width: 567px) {
                    display: none;
                }
            }


            &__item {
                padding: 0 15px;
                font-weight: 300;
                display: flex;



                a {
                    color: $heading;

                    &:hover {
                        color: $hover;
                    }
                }
            }
        }
    }

    .text-danger {

        &.validation-summary-errors {
            background: $red;
            display: flex;
            padding: 6px 15px;
            width: 100%;
            border-radius: 4px;
            margin-bottom: 10px;

            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style-type: none;
                    color: $white;
                }
            }
        }
    }

    .hidden-ui-element {
        display: none !important;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .cookie {
        &.is-active {
            display: flex;
        }

        display: none;
        position: absolute;
        z-index: 999;
        bottom: 50px;
        transform: translateX(-320px);
        max-width: 320px;
        background: $white;
        box-shadow: $tiles-shadow;
        border-radius: 10px;
        padding: 20px 30px;
        flex-direction: column;

        &__desc {
            margin: 15px 0;
            font-size: 14px;
            line-height: 20px;
            color: $text;
            text-align: center;
        }

        &__image {
            width: 100%;
            display: block;
        }

        &__controls {
            display: flex;
        }

        &__btn {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0 5px;

            .ds-btn {
                width: 100%;

                &.text {
                    width: initial;
                }
            }
        }
    }
    //dropdown

    .custom-select-wrapper {
        position: relative;
        user-select: none;
        width: auto;

        .custom-select {
            position: relative;
            display: flex;
            flex-direction: column;

            &__trigger {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 15px;
                font-size: 14px;
                font-weight: 500;
                color: $text;
                height: 54px;
                background: $white;
                background-size: 14px;
                border: solid 1px $input-border;
                border-radius: 4px;
                cursor: pointer;

                .arrow {
                    position: absolute;
                    right: 15px;

                    &::after {
                        display: inline-block;
                        vertical-align: 0.255em;
                        content: "";
                        border-top: 0.3em solid $heading;
                        border-right: 0.25em solid transparent;
                        border-bottom: 0;
                        border-left: 0.25em solid transparent;
                    }
                }



                span {
                    margin-right: 10px;
                    color: $heading;
                    font-weight: 700;

                    &::before {
                        content: "Filter by:";
                        margin-right: 5px;
                        color: $lead;
                        font-weight: 500;
                    }
                }
            }
        }

        .custom-options {
            position: absolute;
            display: block;
            top: 110%;
            right: 0;
            width: 240px;
            padding: 15px;
            border-radius: 4px;
            background: $white;
            box-shadow: $dropdown-shadow;
            transition: all 0.5s;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            z-index: 2;
        }

        .custom-select.open .custom-options {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

        .custom-option {
            position: relative;
            display: block;
            padding: 5px;
            font-size: 14px;
            font-weight: 300;
            color: $text;
            line-height: 26px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                cursor: pointer;
                background-color: $white;
            }

            &.selected {
                color: rgba($color: #777777, $alpha: 0.6);
            }

            .arrow {
                position: relative;
                height: 15px;
                width: 15px;
                transform: scale(0.4);
            }

            .arrow::before,
            .arrow::after {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 0.15rem;
                height: 100%;
                transition: all 0.5s;
            }

            .arrow::before {
                left: -5px;
                transform: rotate(45deg);
                background-color: $app-primary01;
            }

            .arrow::after {
                left: 5px;
                transform: rotate(-45deg);
                background-color: $app-primary01;
            }

            .open .arrow::before {
                left: -5px;
                transform: rotate(-45deg);
            }

            .open .arrow::after {
                left: 5px;
                transform: rotate(45deg);
            }
        }
    }

    #pagination {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        span[data-type="info"] {
            margin: 0 50px;
            color: #666666;

            input[type="number"] {
                width: 27px;
                height: 31px;
                text-align: center;
                display: inline-block;
                border-radius: 4px;
                background-color: #fff;
                color: #666666;
                border: 1px solid #ddd;
                margin-bottom: 0;
            }
        }

        .previous {
            transform: rotate(90deg);
        }

        .next {
            transform: rotate(-90deg);
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */

        input[type="number"] {
            -moz-appearance: textfield;
        }
    }

    label.status,
    .delete-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        padding: 5px 15px;
        border-radius: 50px;
        font-size: 14px;
        margin: 0 10px;
        border: none;

        &.published {
            background: $green;
        }

        &.not-published {
            background: $disabled;
        }
    }

    .delete-btn {
        background: $delete;
    }

    .modal-title {
        font-weight: 700;
    }

    .panel {
        &__thankyou {
            padding: 70px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .animated-container {
                height: 120px;
            }

            .description {
                padding: 0 30px 30px 30px;
                text-align: center;
                max-width: 700px;
            }

            .close-container {
                display: flex;
                justify-content: center;
            }
        }
    }

    .content-container {
        padding: 30px;
        background: $white;
        border-radius: 10px;
        box-shadow: $tiles-shadow;
    }

.status {
    display: flex;
    position: absolute;
    width: 100%;
    margin-top: -39px;
    top: 50%;
    justify-content: center;
    align-items: center;

        &__message {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .icon {
                color: $text;
                font-size: 30px;
                margin-bottom: 5px;
            }
        }
    }

    video {
        max-width: 100%;
    }

    p {
        line-height: 1.6em;
    }


    dfn {
        font-weight: 500;
        color: $heading;
    }
    //ol  {
    //    list-style: none;
    //    counter-reset: section;
    //    padding-left: 32px;
    //    li {
    //        counter-increment: section;
    //        &:before {
    //            content: counters(section, ".") ". ";
    //        }
    //    }
    //}


    .more-information {
        margin: 0 6px;
        display: flex;
        justify-content: center;

        .icon.icon-question-line-icon {
            position: relative;
            top: 1px;
            left: 0;
            font-size: 12px;
        }
    }



    body .tooltip-inner {
        background-color: rgba(0, 0, 0, 1) !important;
        box-shadow: 0px 0px 4px $text;
        opacity: 1 !important;
    }

    body .tooltip.bs-tooltip-top .tooltip-arrow::before {
        border-top-color: rgba(0, 0, 0, 1) !important;
    }


    .pill {
        display: flex;
        letter-spacing: 0.5px;
        justify-content: center;
        align-items: center;
        padding: 3px 10px 2px;
        border-radius: 30px;
        font-size: 11px;
        line-height: 11px;
        text-transform: uppercase;
    }
.pill.draft {
    background: $pill-draft;
    color: $pill-draft-text;
}

.pill.template {
    background: $pill-template;
    color: $pill-template-text;
}

.pill.private {
    background: $pill-private;
    color: $pill-private-text;
    padding-left: 8px;

    span {
        margin-right: 1px;
    }

    &.no-icon {
        padding-left: 0;

        span {
            margin-right: 0;
        }
    }
    
}

.pill.public {
    background: $pill-public;
    color: $pill-public-text;
}




    .pill.authentication {
        background: $red;
        color: $white;
        text-align: center;
    }

    .highlighted-text {
        background: $emphasize;
        padding: 0 8px;
        border-radius: 10px;
        color: $white;
    }


    .required {
        color: $required;
    }



    .swiper {
        width: 100%;
        height: 100%;
        padding-right: 30px;

        &.loginSwiper {
            padding: 0 !important;

            .swiper-wrapper {
                left: 0;

                .swiper-slide {
                    text-align: left;
                    font-size: 18px;
                    border: none;
                    border-radius: initial;
                    background: transparent;
                    width: 100% !important;
                    height: initial;
                    margin: 0;
                }
            }
        }
    }

    .swiper-slide {
        text-align: left;
        font-size: 18px;
        border: solid 1px $border-primary;
        border-radius: 10px;
        background: $white;
        width: 370px !important;
        height: 270px;
        margin-left: 30px;

        @media (max-width: 567px) {
            width: 300px !important;
        }
    }

    .swiper-wrapper {
        position: relative;
        left: -30px;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-button-prev, .swiper-button-next {
        display: none;
    }

.delete__controls {
    position: fixed;
    bottom: 30px;
    right: 60px;
    z-index: 999;

    #delete__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 30px;
        border-radius: 60px;
        width: 60px;
        height: 60px;
        background: $red;
        border: none;
        box-shadow: 0px 6px 30px rgba(0, 62, 97, 0.5);

        &:hover {
            background: $red-hover;
        }
    }
}

    .user-category {
        .badge {
            background: $emphasize;
            border-radius: 20px;
        }
    }





    #auth-container {
        #qrCode {
            margin-bottom: 20px;
        }
         a {
             display: inline-block;
             }

    }



body .toast-info, .toast, .toast-success, .toast-warning, .toast-error {
    background-color: initial !important;
}



body .toast.toast-warning .toast-title {
    color: $orange !important;

    .icon-container {
        background: $orange;
    }
}

body .toast.toast-success .toast-title {
        color: $green!important;

    .icon-container {
        background: $green;
     }
}

body .toast.toast-info .toast-title {
    color: $app-primary01 !important;

    .icon-container {
        background: $app-primary01;
        //font-size: 14px!important;
    }
}

body .toast.toast-error .toast-title {
    color: $red !important;

    .icon-container {
        background: $red;
    }
}



body #toast-container > .toast-info, body #toast-container > .toast-success, body #toast-container > .toast-warning, body #toast-container > .toast-error {
    background-image: none !important;
}
body #toast-container > div {
    opacity: unset!important;
    }

body #toast-container > div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px;
    width: 300px;
    border-radius: 10px;
    color: $heading;
    font-weight: 500;
    @include blurredBG;
    transition: transform 0.3s ease-in-out;


    &:hover {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3) !important;
        -ms-filter: initial !important;
        filter: initial !important;
        cursor: pointer;
    }
}

body .toast {


    .toast-title {
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .icon-container {
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border-radius: 4px;
            font-size: 18px;
            margin-right: 5px;

            .icon {
                position: relative;
                top: 3px;
            }
        }
    }

    .toast-message {
        color: $heading;
        font-weight: 300;
        line-height: 18px;

        .message-title {
            font-weight: 700;
            margin-bottom: 1px;
        }
    }
}

.group-name {
    color: $heading;
    font-weight: 500;
}

.information-box {
    position: relative;
    background: $page-bg-light;
    color: $app-primary01;
    border: solid 1px $border-primary;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 43px;
    margin-bottom: 18px;
    line-height: 20px;
    font-size: 14px;

    .icon {
        position: absolute;
        left: 10px;
        font-size: 24px;
        color: $app-primary01;
    }
    &.column {
        flex-direction: column;
        align-items: flex-start;
        background: $white;
        padding-left: 10px;
            p {
                margin: 0;
            }
    }

}





/*main, .identity__multiple, .nav-header {
    @media (max-width: 1399.8px) {
        display: none;
    }
}

.responsive__notification {
    @media (min-width: 1399.8px) {
        display: none;
    }
}


html, body {
    @media (max-width: 1399.8px) {
        background-color: $page-bg-dark;
    }
}
    */

.responsive {
    &__notification {

        /*@media (max-width: 1399.8px) {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $page-bg-dark;
            width: 100%;
            height: 100%;
            }*/

            @media (max-width: 991px) {
                align-items: initial;
                height: initial;
                padding: 30px;
            }
        }

        &__inner {
            display: flex;
            align-items: center;
            background: $white;
            width: 1000px;
            max-width: 1000px;
            margin: 30px;
            padding: 30px;
            border-radius: 20px;

            @media (max-width: 991px) {
                flex-direction: column;
                margin: 0 0 60px;
            }

            &__left {
                display: flex;
                width: 400px;

                @media (max-width: 991px) {
                    width: initial;
                    justify-content: center;
                    padding: 0 0 30px;
                }
            }

            &__right {
                flex: 1;
            }
        }

        
    }

.registered_trademark {
  font-size: 0.6em;
  position: relative;
  top: -0.6em;
}

.mr-5 {
    margin-right:5px;
}
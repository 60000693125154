﻿//Dashboard
.dashboard {
    background: $white;
    border: solid 1px rgba(0, 59, 105, 0.2);
    border-radius: 10px;
    padding: 0 0 30px;
    margin-bottom: 30px;

    &__block {
    }

    &__title {
        height: 50px;
        border-bottom: solid 1px $border-primary;
        padding: 0 30px;
        display: flex;
        align-items: center;
        color: rgba($black, 0.65);
        font-weight: 700;
        font-size: 14px;
    }

    &__content {
        padding: 30px 30px 0 30px;
    }
}

﻿//body.nav-slide-is-active.no-anim {
//    .nav-slide, .nav-header, main, .hamburger .line {
//        transition: none;
//    }
//}

.nav-slide {
    position: absolute;
    left: -260px;
    top: 0;
    z-index: 0;
    width: 260px;
    height: 100vh;
    background-color: $app-primary01;
    padding: 0;
    transition: all 0.3s ease-in-out;

    .side-nav {
        margin-top: 20px;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                width: 100%;

                a.nav-link {
                    height: 70px;
                    padding: 0 45px;
                    color: $white;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    position: relative;
                    opacity: 0.7;

                    &.active,
                    &:hover {
                        background: rgba($white, 0.2);
                        opacity: 1;
                        text-decoration: none;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 5px;
                            height: 100%;
                            background: $white;
                            display: block;
                        }
                    }

                    .icon {
                        margin-right: 7px;
                        position: relative;
                        top: 1px;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .review {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        background: rgba($black, 0.3);
        width: calc(100% - 60px);
        height: 170px;
        bottom: 30px;
        margin: 0 30px;
        border-radius: 4px;
        padding-top: 80px;

        .lottie {
            position: absolute;
            top: -90px;
        }

        &__desc {
            text-align: center;
            color: $white;
            font-size: 12px;
            margin-bottom: 10px;
        }

        .feedback {
            width: 100%;
            color: $white;
            background: $app-primary01;
            position: relative;
            padding: 0;
            height: 44px;

            a.btn {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: $white;
                font-size: 12px;
                background: $app-primary01;
                transition: all 0.3s ease-in-out;

                &:hover {
                    text-decoration: none;
                    background: $hover;
                }

                &:focus {
                    text-decoration: none;
                    background: $hover;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
}


.nav-header {
    width: 100%;
    transition: all 0.3s ease-in-out;
    height: 80px;
    background-color: $white;
    border-bottom: solid 1px $border-primary;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    .app__logo {
        margin-left: 30px;
        width: 150px;
        max-width: 150px;
        height: auto;
    }


    .menu {
        position: absolute;
        right: 30px;
        display: flex;
        align-items: center;

        &__main {
            display: flex;

            .menu__item.active {
                a {
                    color: $hover;
                }
            }
        }

        &__item {
            justify-content: center;
            display: flex;
            margin-left: 50px;

            &.notification {
                position: relative;

                .notification__number {
                    color: $white;
                    background: $red;
                    width: 6px;
                    height: 6px;
                    border-radius: 10px;
                    text-align: center;
                    position: absolute;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    top: 15px;
                    left: 25px;
                    font-size: 10px;
                    z-index: 2;

                    &.is-active {
                        display: flex;
                    }
                }
            }

            @media (max-width: 1200px) {
                margin-left: 30px;
            }

            @media (max-width: 991px) {
                display: none;

                &.notification {
                    display: block;
                }
            }



            .icon {
                font-size: 18px;
                position: relative;
                top: 3px;
                left: -1px;
            }

            .logout {
                color: $emphasize !important;

                .icon-logout-icon {
                    margin-left: 0;
                }
            }

            a {
                color: $header-nav;

                &:hover {
                    color: $hover;
                }

                span {
                    margin-left: 7px;
                }
            }

            &.notification {
                margin-left: 0;

                a.notification-icon {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        left: -4px;
                    }

                    &:hover {
                        box-shadow: $button-shadow;
                    }

                    &:focus {
                        box-shadow: $button-shadow;
                        background: $white;
                    }
                }
            }

            a.login {
                padding: 0 10px;

                &:hover {
                    text-decoration: none;
                    color: $app-primary01;
                }
            }
        }
    }

    .restricted {
        margin-left: 30px;

        .btn-secondary {
            color: $header-nav;

            .identity {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 2px;
                position: relative;

                &__role {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 300;
                    text-align: left;
                    width: 100%;
                    color: $lead;

                    &__multiple {
                        text-align: left;
                        font-size: 12px;
                        line-height: 14px;
                        color: $dark-grey;
                        display: flex;
                        align-items: center;
                    }
                }

                &__name {
                    line-height: 20px;
                    color: $header-nav;
                    max-width: 200px;
                    padding-right: 15px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    position: relative;


                    &::after {
                        position: absolute;
                        right: 0;
                        bottom: 7px;
                        display: inline-block;
                        margin-left: 10px;
                        vertical-align: 0.255em;
                        content: "";
                        border-top: 0.3em solid;
                        border-right: 0.25em solid transparent;
                        border-bottom: 0;
                        border-left: 0.25em solid transparent;
                    }
                }
            }

            &:hover {
                box-shadow: none;
            }
        }

        .profile-pic {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            border: solid 2px $white;
            border-radius: 50%;
            box-shadow: $avatar-shadow;
            color: $white;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
        }

        .btn-secondary,
        .btn-secondary:not(:disabled):not(.disabled):active,
        .show > .btn-secondary.dropdown-toggle {
            width: auto;
            height: auto;
            background-color: transparent;
            border-color: transparent;
            border-radius: 0;
            box-shadow: none;
            color: $header-nav;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }

        .btn.btn-secondary.dropdown-toggle.show {
            box-shadow: none;
        }
    }

    .btn-secondary,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
        width: 50px;
        height: 50px;
        background-color: transparent;
        border-color: transparent;
        border-radius: 50%;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $header-nav;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        &:hover {
            box-shadow: $button-shadow;
            color: $app-primary01;
        }
    }

    .btn.btn-secondary.dropdown-toggle.show {
        box-shadow: $button-shadow;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        border: none;
        border-radius: 10px;
        box-shadow: $dropdown-shadow;
        width: 225px;
        top: 5px !important;

        &:after {
            content: "";
            position: absolute;
            top: -5px;
            right: 20px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $white;
        }

        li {
            font-size: 14px;
            margin-bottom: 5px;
            line-height: 20px;

            .dropdown-item {
                white-space: normal;
                color: $text;
                font-weight: 300;

                &:active {
                    color: $white;
                }
            }
        }
    }

    .hamburger {
        position: relative;
        left: 15px;
        transform: scale(0.5);


        @media (min-width: 992px) {
            display: none;
        }



        .line {
            width: 50px;
            height: 5px;
            background-color: $app-primary01;
            display: block;
            margin: 8px auto;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }

    .hamburger:hover {
        cursor: pointer;
    }
    /* ONE */
}

    body.nav-slide-is-active {
        #hamburger-1 .line:nth-child(2) {
            opacity: 0;
        }

        #hamburger-1 .line:nth-child(1) {
            -webkit-transform: translateY(13px) rotate(45deg);
            -ms-transform: translateY(13px) rotate(45deg);
            -o-transform: translateY(13px) rotate(45deg);
            transform: translateY(13px) rotate(45deg);
        }

        #hamburger-1 .line:nth-child(3) {
            -webkit-transform: translateY(-13px) rotate(-45deg);
            -ms-transform: translateY(-13px) rotate(-45deg);
            -o-transform: translateY(-13px) rotate(-45deg);
            transform: translateY(-13px) rotate(-45deg);
        }
    }
    //may not be needed once taken out of template
    secondary-nav {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    body.logged-in {
        .profile {
            display: none;
        }

        .menu .menu__item.restricted {
            display: flex;
        }
    }

    .menu .menu__item.restricted {
        display: none;
    }


    .menu .menu__item.restricted.impersonating {

        .profile-pic {
            border-color:$red;

            }
    }


// category navigation in left column
ul.category-nav {
    margin: 0;
    padding: 0 0 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    li.category {
        color: $lead;
        font-weight: 500;
        padding-left: 0;
        height: auto;
    }

    li {
        list-style-type: none;
        display: flex;
        align-items: center;
        height: 42px;
        font-weight: 300;
        font-size: 14px;
        //margin-bottom: 5px;

        a {
            position: relative;
            color: $text;
            width: 100%;
            height: 100%;
            padding-left: 30px;
            display: flex;
            align-items: center;

            &:hover {
                color: $text;
            }

            &.active {
                background: $active-nav;
                border-radius: 4px;
                color: $white;

                &::after {
                    content: url(../assets/images/icons/right-arrow-icon-white.svg);
                    position: absolute;
                    right: 20px;
                    top: 10px;
                }

                &:hover {
                    background: $active-nav;
                    color: $white;
                }
            }

            &:hover {
                text-decoration: none;
                background: $hover-grey;
                border-radius: 4px;
            }
        }
    }
}



.identity__multiple {
    &.is-shown {
        display: block;
    }

    position: absolute;
    z-index: 3;
    top: 43px;
    right: 47px;
    width: 200px;
    @include blurredBG;
    border-radius: 10px;
    padding: 15px 15px 10px 20px;
    display: none;

    p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: $heading;
        margin-bottom: 10px;
    }



    ul {
        margin: 0;
        padding-left: 15px;

        li {
            font-size: 14px;
            color: $heading;
            font-weight: 300;
            line-height: 20px;
            margin-bottom: 5px;
        }
    }
}


.impersonate {
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
    width: 330px;
    line-height: 20px;
    background: $dark-grey;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    z-index: 999;

    &__notice {
        z-index: 999;
        color: #2e3a59;
        font-weight: 500;
        color: $white;
        display: flex;
        align-items: center;


        .icon-profile-icon {
            margin-right: 7px;
            position: relative;
            top: 1px;
        }
    }

    &__status {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 14px;
        color: $medium-grey;
    }

    &__user {
        width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        line-height: 16px;
        opacity: 1;
        color: $white;
    }

    &__close {
        position: absolute;
        right: 10px;
        color: $white;
        z-index: 2;

        &:hover {
            color: $white;
        }

        .icon-close-icon {
            position: relative;
            top: 1px;
        }
    }
}





    body.nav-slide-is-active {




        .nav-slide {
            left: 0;
            z-index: 999;


            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 260px;
                width: calc(100vw + 260px);
                height: 100vh;
                background: rgba($dark-grey, 0.3);
                z-index: 0;
            }
        }

        .nav-header {
            width: 100%;
            left: 0;
            //@media (min-width: 1750px) {
            //    width: calc(100% - 260px);
            //    left: 260px;
            //}
        }

        main {
            width: 100%;
            left: 0;
            //@media (min-width: 1750px) {
            //    width: calc(100% - 260px);
            //    left: 260px;
            //}
        }

        .hamburger {
            position: relative;
            left: 260px;
            transform: scale(0.5);
            z-index: 1001;

            .line {
                width: 50px;
                height: 5px;
                background-color: $active-nav;
                display: block;
                margin: 8px auto;
                -webkit-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        border-color: $border-primary $border-primary #fff;
    }
﻿.ds-btn {
    height: 44px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $app-primary01;
    color: $white;
    border-radius: 4px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    border: none;
    padding: 0 15px;
    border: solid 1px transparent;
    white-space: nowrap;




    &:hover, &:focus {
        text-decoration: none;
    }

    &.next {
        padding: 0 15px 0 20px;

        .icon {
            color: $white;
            transform: rotate(-90deg);
            margin-left: 15px;
            font-size: 13px;
        }
    }

    &.delete {
        background: $red;

        &:hover {
            background: $red-hover;
        }
    }



    &.text {
        border: none;
        background: transparent;
        color: $app-primary01;
        height: initial;
        display: inline-block;
        padding: 0;

        .icon.icon-close-icon {
            font-size: 24px;
        }

        &.cancel-active {
            color: $emphasize;
        }



        &:hover {
            border: none;
            background: transparent;
            color: $hover;
        }


        &:focus {
            background: transparent;
            border: none;
        }

        &.cancel {
            color: $emphasize;
            padding: 0 10px;

            &:hover {
                border: none;
                background: $page-bg-dark;
                border-radius: 4px;
            }
        }
    }

    &.outline {
        background: $white;
        border: solid 1px $border-primary;
        color: $app-primary01;
        padding: 0 25px;

        .icon {
            position: relative;
            top: 1px;
            margin-right: 5px;
            color: $emphasize;
            font-size: 20px;
        }

        &.show {
            padding: 0 25px !important;
        }

        &.previous {
            padding: 0 25px 0 10px;

            .icon {
                color: $app-primary01;
                transform: rotate(90deg);
                font-size: 13px;
                margin-right: 12px;
                top: 0;
            }
        }

        &:focus,
        &:hover,
        &:active {
            .icon {
                color: $white;
            }
        }
    }

    &:focus {
        background: $hover;
        color: $white;
        border: solid 1px transparent;
    }

    &:hover {
        background: $hover;
        color: $white;
    }
}

.clear-form {
    color: $app-primary01;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.action {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn-group:last-child {
        margin-left: 5px;
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px $border-primary;
        color: $app-primary01;
        padding: 0 20px;
        height: 34px;
        border-radius: 4px;
        background: $white;
        margin: 0 5px;
        font-size: 14px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: $hover-grey;
        }
    }

    #publish {
        background: $app-primary01;
        color: $white;

        &:hover {
            background: $hover;
        }
    }
}

button.add_btn {
    padding: 0 10px;
    border: none;
    font-size: 14px;
    background: transparent;
    color: $emphasize;
    font-weight: 500;
    .icon {
        position: relative;
        top: 2px;
    }
}

.modal-header {
    button {
        color: $emphasize;
    }
}

.btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    color: $active;
    display: inline-block;
}

.back-btn {
    border: none;
    background: transparent;
    color: $emphasize;
    .icon {
        margin-right: 4px;
        transform: rotate(90deg);
        display: inline-block;
        font-size: 14px;
    }
}

.dropdown-menu {
    text-align: left;
    list-style: none;
    background-color: $white;

    border: none;
    border-radius: 4px;
    box-shadow: $dropdown-shadow;
    .dropdown-item {
        color: $text;
        font-weight: 300;
        font-size: 14px;
    }
}


//.button {
//    display: flex;
//    justify-content: center;
//    .ds-btn.outline {
//        width: initial;
//     }
//}

.btn-group {

    button {

        &::after {
            display: inline-block;
            margin-left: 10px;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.25em solid transparent;
            border-bottom: 0;
            border-left: 0.25em solid transparent;
        }

        &.logout {
            &::after {
                content: "";
                display: none;
            }
        }
    }

    ul {
        padding-left: 0;
    }
}


.btn-group.full {
    display: initial;

    .ds-btn {
        justify-content: flex-start;

        &::after {
            position: absolute;
            right: 15px;
            }
        }

        .dropdown-menu {
            width: 100%;
            min-width: 100%;
        }
    }



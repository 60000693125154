﻿//custom modals

.custom-modal {
    .modal-body-inner {
        padding: 0 5px 40px 5px;
    }

    .modal-content {
        background: $white !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3) !important;
        border: 1px solid $border-primary !important;
    }

   

    
    .modal-footer {
        padding: 0;
        display: flex;
        justify-content: space-between;
        border: none;

        button {
            flex: 1;
            margin: 0;

            &:first-child {
                margin-right: 7.5px;
            }

            &:last-child {
                margin-left: 7.5px;
            }
        }
    }
}
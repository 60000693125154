﻿// Requests


.pause {
    padding: 5px 15px;
    background: $white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $text;
    &__legend {
        width: 14px;
        height: 14px;
        position: relative;
        top: 2px;
        display: inline-block;
        background: $paused;
        margin-right: 7px;
        border: solid 1px $border-primary;
    }
}

.request {
    &__personal {
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px $input-border;
        padding: 20px 0 20px 0;
        margin: 0 50px 30px 0;
        .request-row {
            display: flex;
            margin-bottom: 5px;
            span.field {
                color: $heading;
                font-weight: 500;
                margin-right: 7px;
            }
        }
    }
    &__block {
        margin: 0 50px 30px 0;
    }
    &__title {
        color: $heading;
        font-weight: 500;
    }
    .organisation {
        display: flex;
        flex-direction: column;
        padding: 20px 0 20px 0;
        &__name {
            font-size: 18px;
            font-weight: 700;
            color: $heading;
        }
        &__abn {
            font-size: 14px;
            font-weight: 300;
            color: $text;
        }
    }
    &__terms {
        background: $page-bg-dark;
        padding: 30px;
        border-radius: 10px;
    }
    &__bundle {
        display: flex;
    }
    &__data {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__lead {
        font-size: 12px;
        line-height: 12px;
        margin-top: 4px;
        margin: 4px 0 3px 0;
        color: $text;
        font-weight: 300;
    }
    &__name {
        font-size: 16px;
        line-height: 16px;
        color: $heading;
        font-weight: 700;
        margin-bottom: 30px;
        .version {
            color: $emphasize;
            font-size: 12px;
            margin-left: 5px;
            font-weight: 500;
        }
    }
    &__infomation {
        margin: 20px 0 20px;
    }
    .control_indicator {
        position: absolute;
        top: 7px;
    }
    label.control.control-checkbox {
        line-height: 18px;
        font-weight: 500;
        color: $heading;
    }
    .cal-wrapper {
        width: inital;
    }
    .date-range {
        width: 100%;
        max-width: 100%;
    }
}

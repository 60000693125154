//Swiper
.swiper {
    width: 100%;
    height: 380px;
    z-index: 0;
    padding: 0 20px 50px;
    position: relative;
}

.swiper-slide {
    padding: 30px;
    box-sizing: border-box;
    text-align: center;
    height: 100%;
    width: 240px;
}

// .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }
.tile-container {
    position: initial;
}

.carousel-container {
    position: relative;
    width: calc(100% + 60px);
    left: -30px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 260px, rgba(247, 249, 254, 1) 260px, rgba(247, 249, 254, 0.01) 100%);
}

.formOccurrence.swiper-horizontal>.swiper-pagination-bullets {
    bottom: -6px;
    left: 0;
    width: 100%;
}

.loginSwiper.swiper-horizontal>.swiper-pagination-bullets {
    bottom: 25px;
    left: 0;
    width: 100%;
}
﻿// Requests

.record-row {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $border-primary;
    padding: 10px 0;


    &.is-paused {
        background: $paused;
    }

    &.heading {
        .item,
        .record-request, .last {
            color: $heading;
            font-weight: 500;
        }

        .record-request {
            width: 105px;
            padding-right: 20px;
        }

        .item {
            padding: 0;

            &.name {
                width: 300px;
                flex: initial;
            }
        }

        .item .email {
            text-transform: initial;
            max-width: 270px;
        }
    }

    .record-avatar {
        margin-left: 20px;
        width: 80px;
    }

    .record-request {
        width: 105px;
        padding-right: 20px;
    }


    .item {
        .name, .bundle, .role, .time, .email, .identification {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .record-received {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 250px;

            @media (max-width: 1600px) {
                max-width: 120px;
            }
        }

        .email {
            text-transform: lowercase;
            max-width: 270px;

            @media (max-width: 1600px) {
                max-width: 190px;
            }
        }

        .role {
            width: 200px;
        }

        .name {
            width: 238px;
            padding-right: 40px;
        }

        &.elapsed {
            display: flex;
            justify-content: flex-end;
            width: 130px;
            padding-right: 20px;
        }

        &.selected {
            display: flex;
            justify-content: flex-end;
            width: 130px;
            padding-right: 20px;
        }

        .status {
            width: 140px;
            display: flex;
            justify-content: flex-end;
        }

        &.status {
            position: initial;
            width: initial;
            margin-top: initial;
            top: initial;
            justify-content: initial;

            .status {
                width: 140px;
                display: flex;
                justify-content: flex-end;
                position: initial;
                width: initial;
                margin-top: initial;
                top: initial;
            }
        }



        &.last {
            width: 140px;
            display: flex;
            justify-content: flex-end;

            .action, .ip {
                width: 140px;
                justify-content: flex-start;
            }

            .status {
                width: 140px;
                justify-content: flex-start;
                position: initial;
                margin-top: initial;
            }
        }



        &.stack {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;
            width: 300px;
            flex: initial;

            &.not-fixed {
                flex: 1;
            }

            .record-role {
                display: flex;
                background: $emphasize;
                color: $white;
                border-radius: 15px;
                line-height: 14px;
                font-size: 14px;
                margin-top: 3px;
                padding: 3px 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .record-name {
                color: $heading;
                font-weight: 500;
                line-height: 18px;
                margin-top: 3px;
                padding-right: 30px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .record-org {
                width: 100%;
                padding-right: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                line-height: 18px;
            }
        }



        .record-request {
            display: flex;
            width: 140px;


            button {
                width: 100px;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }












    .avatar {
        margin-left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: solid 1px $white;
        box-shadow: $avatar-shadow;
        display: flex;
        background: $emphasize;
        color: $white;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        line-height: 40px;
        font-size: 12px;
    }



    .bundle {
        //width: 200px;
        max-width: 200px;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 1600px) {
            max-width: 120px;
        }
    }

    .item {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 10px 0;
        color: $text;
    }





    .record-organistaion {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 170px;
    }

    .record-submitted {
        padding: 10px 15px;
        border-radius: 4px;
        color: $white;
        background: $emphasize;
        width: 100px;
    }
}


﻿
.account-setup {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    background: $white;

    .registration-logout {
        position: absolute;
        top: 30px;
        right: 50px;
        z-index: 99;

        button {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: $text;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .icon {
            position: relative;
            top: 3px;
        }
    }

    .header {
        margin: 20px 0 0 20px;
        position: fixed;
        top: 0;
        left: 0;
    }

    .process-steps {
        display: flex;
        align-items: center;
        padding: 0 30px 0 60px;
        width: 440px;
        align-items: center;
        background-color: $page-bg-dark;

        &__inner {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .stage {
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            &__item {
                position: relative;
                width: 40px;
                height: 40px;
            }

            &__tick, &__number {
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__number {
                color: $active-nav;
                border: solid 2px $active-nav;
                z-index: 2;
                font-size: 18px;
                font-weight: 700;
            }

            &__tick {
                color: $white;
                border: solid 2px $green;
                background: $white;
                z-index: 1;
                display: none;
            }

            &__name {
                flex: 1;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $app-primary01;
                padding: 0 0 0 20px;
                opacity: 50%;
            }
        }
    }

    &__main {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__content {
        padding: 50px 70px;
    }

    .step {
        margin-top: 60px;
        width: 750px;
        max-width: 750px;
        display: flex;
        flex-direction: column;

        &.terms-conditions {
            .description p {
                max-width: 650px;
            }

            .terms {
                max-height: 600px;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                margin-bottom: 15px;
            }
        }

        .description {
            max-width: 650px;
            margin: 0 0 10px 0;

            h3 {
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 15px;
            }

            p {
                max-width: 420px;
            }
        }

        .checkboxes {
            margin-top: 20px;

            p {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 300;
            }

            .form-group {
                margin-right: 25px;

                &:last-child {
                    margin-right: 0;
                }
            }





            .controls {
                width: initial;
                padding-right: 30px;
                margin-top: 0;
                display: flex;
                justify-content: flex-start
            }
        }

        .controls {
            margin-top: 50px;
            justify-content: space-between;

            &.single {
                justify-content: flex-end;
            }
        }
    }
}



// turning stages on and off

.step-one {
    .business-details, .terms-conditions {
        display: none;
    }
    .process-steps .stage.one .stage__name {
        opacity: 100%;
    }

}


.step-two {
    .personal-details, .terms-conditions {
        display: none;
    }
    .process-steps .stage.two .stage__name {
        opacity: 100%;
    }

    .process-steps .stage.one .stage__number  {
        display: none;
    }
    .process-steps .stage.one .stage__tick  {
        display: flex;
    }
    .process-steps .stage.one .stage__name {
        opacity: 100%;
    }

    
}


.step-three {
    .personal-details, .business-details {
        display: none;
    }
    .process-steps .stage.three .stage__name {
        opacity: 100%;
    }
     .process-steps .stage.one .stage__number  {
        display: none;
    }
    .process-steps .stage.one .stage__tick  {
        display: flex;
    }
    .process-steps .stage.one .stage__name {
        opacity: 100%;
    }
    .process-steps .stage.two .stage__number  {
        display: none;
    }
    .process-steps .stage.two .stage__tick  {
        display: flex;
    }
    .process-steps .stage.two .stage__name {
        opacity: 100%;
    }
}
﻿//Bundle info
#productAnimInformationModal {
    position: relative;
    height: 100%;

    .modal-body {
        border-left: solid 1px $border-primary;
        border-right: solid 1px $border-primary;
    }

    .modal-footer {
        display: flex;
        flex-wrap: initial;
        flex-shrink: initial;
        align-items: center;
        justify-content: center;

        button {
            width: initial;
        }
    }

    .page-info-container {
        width: 100%;
        height: calc(100% - 110px);
        position: relative;
        padding: 25px 25px 20px 25px;
        border: solid 1px $border-primary;
        border-top: 0px;
        border-radius: 0px 0px 10px 10px;
        overflow: hidden;
        overflow-y: auto;

        .tab-content {
            .tab-pane {
                padding: 0px;
            }
        }
    }

    .input-daterange input {
        text-align: left;
        width: 100%;
    }

    input.date-range {
        padding-right: 27px;
    }

    .bundle {
        &__header {
            display: flex;
            justify-content: space-between;
        }

        &__data-sources {
            padding: 3px 10px;
            background: $page-bg-dark;
            border-radius: 4px;
            color: $app-primary01;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-right: 20px;
        }

        &__name {
            padding: 0 20px;
        }

        &__description {
            max-width: 800px;
            padding: 0 20px;
            margin-bottom: 30px;
        }
    }

    .product {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    #bundleModalConfirmPanel.product {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        background: $white;
    }

    .content {
        padding: 0 20px;
        
        .data-source-list {
            :first-child.data-source {
                border-top: solid 1px rgba(0, 59, 105, 0.2);
            }
        }
    }
}

.data-source {
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 0;
    border-bottom: solid 1px $border-primary;
    font-size: 14px;

    &__name,
    &__description {
        display: flex;
        align-items: center;
        padding: 0 80px 0 15px;
        line-height: 20px;
        background: $white;
        position: relative;

        .get-btn {
            letter-spacing: 0.5px;
            justify-content: center;
            align-items: center;
            padding: 4px 10px 4px;
            border-radius: 4px;
            font-size: 11px;
            line-height: 11px;
            text-transform: uppercase;
            color: $app-primary01;
            width: initial;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 20px;
            background: $page-bg-dark;
            font-weight: 700;
        }

        &__box {
            width: 90%;
        }
    }

    &__name {
        width: 370px;
        padding: 0 15px;
        color: $heading;



        &__inner {
            width: 100%;
            display: flex;
            align-items: center;
        }
    }

    &__item {
        display: block;
        width: 80%;
        line-height: 20px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__icon {
        color: $emphasize;
        display: flex;
        justify-content: center;
        margin-right: 8px;
        position: relative;
        top: 2px;
    }

    &__description {
        flex: 1;
    }

}.collapsed {
    border-top: 0px
}

.parameter-collapse {
    border-bottom: solid 1px rgba(0, 59, 105, 0.2);
}

.parameter-list{
    margin: 15px;

    .parameter-heading {
        display: flex;
        border-bottom: dashed 1px rgba(0, 59, 105, 0.2);
        font-size: 14px;
        padding: 5px 5px 8px 30px;
        font-weight:  500;
    }
}

.data-source-parameter {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 5px 5px 8px 30px;

    &__name {
        width: 250px;
        font-weight: 500;
    }

    &__description {
        flex: 1;
        width: 250px;
    }

    &__whitelist {
        display: flex;
        padding: 16px;

        .whitelist-text {
            font-style: italic;
            min-width: 120px;
        }
    }

    &__type {
        width:100px;
        font-size: 14px;
    }

    &__data-type {
        width:100px;
        font-size: 14px;
    }

    &__required {
        color: rgba(255,0,0,.6);
    }
    &__required:after  {
        font-size: 12px;
        position: relative;
        top: -6px;
        padding: 5px;
        content: "required";
    }
    
}

.share-list {
    .share{
        display: flex;
        align-items: center;
        flex: 1;
        padding: 10px 0;
        color: #828282;
    }

    .share.first{
        min-width:375px;
    }

    .share.hidden{
        flex:0;
    }
}
﻿//Accordion

.accordion {
    .accordion-button {
        margin: 0;

        &.collapsed {
            color: $text;
            font-size: 16px;
        }

        color: $emphasize;




        &:focus {
            z-index: 3;
            border: none;
            outline: 0;
            box-shadow: none;
        }

        .nav-tabs .nav-link:focus,
        .nav-tabs .nav-link:hover {
            border-color: transparent transparent transparent;
            isolation: isolate;
        }

        .nav-tabs {
            border-bottom: 1px solid transparent;
        }

        .tab-pane {
            padding: 15px;
        }

        .accordion-body {
            padding: 0;
        }
    }

    .button {
        display: flex;
        justify-content: center;

        button.ds-btn.outline {
            max-width: 300px;
        }
    }
}

.organisation-list {
    border: solid 1px $border-primary;
    border-radius: 10px;
    overflow: hidden;
}

.accordion.admin {
    padding: 0 0;
    margin-bottom: 0px;
    background: $white;
    border-radius: 0px;
    overflow: hidden;
    border-top: solid 1px $border-primary;

    &:first-child {
        border-top: none;
    }
    //box-shadow: $dropdown-shadow;

    .checkbox__label::before {
        content: '';
        border-radius: 50%;
    }

    .checkbox__input ~ .checkbox__label:after {
        content: '';
        position: absolute;
        top: 2px !important;
        left: 2px !important;
    }

    .record-row {
        padding: 0 !important;
        margin: 0 25px 0 25px;

        .col__list__select {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .checkbox.user-select {
                display: flex;
                position: relative;
                top: -11px;
                width: 40px;
            }
        }
    }

    .accordion-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 25px;
        background: $white;
        border: none;

        .col__list__select {
            position: relative;
            top: -4px;
        }

        .record-row-group .col__list__select {
            position: relative;
            top: 0;
        }

        .item.action-items {
            justify-content: flex-end;
        }


        .accordion-header, .accordion-controls, .controls-inner {
            display: flex;
            align-items: center;
        }

        .accordion-controls {
            margin-bottom: 0;
        }



        .accordion-header {
            .org-name {
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                margin: 0;
                color: $app-primary01;
            }
        }

        .accordion-controls {
            .controls-inner {
                button {
                    display: flex;
                    align-items: center;

                    .icon {
                        font-size: 22px;
                        margin-right: 4px;
                    }
                }

                .col__controls {
                    button {
                        margin-left: 15px;

                        &.cancel-active {
                            color: $emphasize;
                        }
                    }
                }
            }

            button.accordion-button {
                box-shadow: none;
                background: none;
            }
        }
    }

    .collapse, .collapsing, .show {
        padding: 0;

        .record-row {
            .badge {
                background: $app-primary01;
                color: $white;
                border-radius: 40px;
                text-transform: uppercase;
                font-weight: 300;
                letter-spacing: 0.5px;
                font-size: 10px;
            }
        }
    }
}

//.accordion.admin .collapse.show {
    //padding-bottom: 25px;
//}


.accordion.admin .collapse, .accordion.admin .collapsing {
    padding-bottom: 0px;
}




.accordion.accordion-flush {
    &.organisation-groups {

        ul.dropdown-menu {
            width: 250px;
        }
    }



    .accordion-item {
        flex-direction: column;
        background: $white;
        margin: 0 50px;
        padding: 0;


        .accordion-header {
            width: 100%;
            background: $page-bg-light;
            border-top: solid 1px $border-primary;




            .record-row-group {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .item-group-name {
                    display: flex;

                    .col__list__select {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .checkbox.group-select {
                            display: flex;
                            justify-content: center;
                            position: relative;
                            top: -11px;
                            width: 40px;
                        }
                    }

                    .group-name-text-wrapper {
                        display: flex;
                        align-items: center;
                        padding-left: 15px;

                        span.input.editting-group-text {
                            background: $white;
                            padding: 3px 5px;
                            border-radius: 4px;
                            border: solid 1px $border-primary;

                            &:focus-visible {
                                outline: none;
                            }
                        }
                    }
                }


                input {
                    width: initial;
                    display: flex;
                    background: transparent;
                    border: none;
                    margin-left: 15px;
                }
            }
        }

        .collapse, .collapsing, .show {
            width: 100%;
            padding: 0;
        }
    }
}



.accordion.admin .record-row:first-child {
    margin: 0px 25px 0 25px;
    border-top: solid 1px $border-primary;
}

.accordion.admin .accordion.accordion-flush .record-row {
    margin: 0;
}


    .accordion-button:not(.collapsed)::after {
        background-image: url(../assets/images/icons/down-icon.svg);
        transform: rotate(-180deg);
    }

    .accordion.admin .accordion.accordion-flush .record-row:first-child, .accordion.admin .accordion.accordion-flush .record-row:last-child {
        margin: 0;
       
    }
    .accordion.admin .accordion.accordion-flush .record-row:last-child {
        border-bottom: none;
    }


    //stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class
    //selects all .accordion flush and gives a margin top of 14px
.collapse > .accordion-flush, .collapsing > .accordion-flush, .show > .accordion-flush {
    margin-top: 14px;
}
    //Select all but the first .accordion-flush of .collapse and remove the margin-top from the previous rule.
.collapse > .accordion-flush ~ .accordion-flush, .collapsing > .accordion-flush ~ .accordion-flush, .show > .accordion-flush ~ .accordion-flush {
    margin-top: 0;
}


.collapse > .accordion-flush:first-child .accordion-item {
    border-top: solid 1px $border-primary;
    margin-bottom: 0px;
}


.collapse > .record-row:last-child {
   margin-bottom: 0px;
  
    
}

.group-name-text-controls {
    .ds-btn.text {
        margin-left: 5px;
        font-size: 12px;
        }
}


.accordion-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2px;
    button {
        width: initial;
        }

    }


.accordion.accordion-flush.organisation-groups {
    .accordion-item.organisation-group {
        &:last-child {
            border-bottom: solid 1px $border-primary;
            margin-bottom: 25px;
        }
    }
}
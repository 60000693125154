﻿

.external {
    background: $white;

    .tile__inner {
        background: $white;
    }

    .tile__title {
        margin-bottom: 15px;
    }

    .tile__body {
        padding: 0;
    }

    .tile__name {
        padding-left: 5px;
        display: flex;

        .profile-pic {
            margin-right: 5px;
            position: relative;
            top: 2px;
        }
    }

    .tile__status {
        padding-right: 5px;
    }

    .tile__author {
        padding-left: 5px;
        }


        .tile__desc {
        margin-top: 20px;
        font-size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .tile__busname {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header {
        background: $white;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        border-bottom: solid 1px $border-primary;
        padding: 0 40px;

        @media (max-width: 767px) {
            height: 75px;
        }

        @media (max-width: 600px) {
            padding: 0 15px;
        }

        &__left {
            display: flex;
            align-items: center;
            color: #777777;

            .dpird__logo {
                display: flex;
                align-items: center;

                img {
                    width: 180px;
                    max-width: 180px;
                    height: auto;
                    margin-right: 20px;

                    @media (max-width: 400px) {
                        width: 90px;
                        max-width: 90px;
                    }
                }
            }

            .logo {
                margin: 0;

                img {
                    width: 180px;
                    max-width: 180px;
                    height: auto;
                    transform: scale(90%);

                    @media (max-width: 767px) {
                        width: 180px;
                        max-width: 180px;
                    }

                    @media (max-width: 400px) {
                        width: 90px;
                        max-width: 90px;
                    }
                }
            }
        }

        &__right {
            .statement {
                color: $text;

                img {
                    max-width: 90px;

                    @media (max-width: 991px) {
                        max-width: 75px;
                    }

                    @media (max-width: 767px) {
                        max-width: 60px;
                    }

                    @media (max-width: 400px) {
                        width: 50px;
                        max-width: 50px;
                    }
                }
            }
        }
    }




    &__introduction {
        padding: 75px 0;
        width: 100%;
        display: flex;
        justify-content: center;



        &__container {
            width: 1320px;

            @media (min-width: 576px) {
                max-width: 100%;
                padding: 0 15px;
            }

            @media (min-width: 768px) {
                max-width: 100%;
                padding: 0 30px;
            }

            @media (min-width: 992px) {
                max-width: 960px;
            }

            @media (min-width: 1200px) {
                max-width: 1140px;
            }

            @media (min-width: 1400px) {
                max-width: 1320px;
            }
        }

        &__row {
            display: flex;
            justify-content: space-between;

            @media (max-width: 991px) {
                justify-content: initial;
                flex-direction: column;
                align-items: center;
            }

            @media (max-width: 500px) {
                align-items: flex-start;
                padding: 0 15px;
            }
        }

        &__aside-left {
            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }


        &__aside-rigth {
            width: 100%;
            max-width: 440px;

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }

            @media (max-width: 500px) {
                max-width: 100%;
            }
        }

        .cta {
            margin-top: 30px;
            display: flex;

            .ds-btn {
                width: initial;
            }

            @media (max-width: 991px) {
                display: none;
            }
        }


        @media (max-width: 1400px) {
            padding: 50px 0;

            .container {
                max-width: 610px;

                @media (max-width: 767px) {
                    width: 100%;
                    padding: 0 30px;
                }
            }
        }

        @media (max-width: 991px) {
            padding: 0;
        }
    }

    &__lead {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 18px;
        color: $heading;
        margin-bottom: 20px;
        margin-top: 60px;

        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }

    &__headline__mobile {
        display: none;



        @media (max-width: 1200px) {
            display: flex;
            flex-direction: column;

            .line {
                height: 40px;
                width: 100%;
                display: block;
                overflow: hidden;

                h2 {
                    font-size: 34px;
                    line-height: 40px;
                    transform: translateY(40px);
                }
            }
        }

        @media (max-width: 500px) {
            display: flex;
            flex-direction: column;

            .line {
                height: 32px;
                width: 100%;
                display: block;
                overflow: hidden;

                h2 {
                    font-size: 24px;
                    line-height: 32px;
                    transform: translateY(32px);
                }
            }
        }
    }


    &__headline {
        display: flex;
        flex-direction: column;

        @media (max-width: 1200px) {
            display: none;
        }

        .line {
            height: 40px;

            @media (max-width: 767px) {
                height: 24px;
            }

            width: 100%;
            display: block;
            overflow: hidden;

            h2 {
                font-size: 34px;
                line-height: 40px;
                color: $heading;
                transform: translateY(50px);

                @media (max-width: 767px) {
                    font-size: 17px;
                    line-height: 24px;
                    transform: translateY(24px);
                }
            }
        }
    }




    &__list {
        width: 100%;
        display: flex;

        @media (max-width: 1130px) {
            flex-wrap: wrap;
        }

        &__item {
            flex: 1;
            opacity: 0;
            padding-left: 70px;
            border-top: solid 1px $input-border;
            border-right: solid 1px $input-border;
            border-bottom: solid 1px $input-border;
            display: flex;
            align-items: center;
            height: 100px;

            @media (max-width: 1130px) {
                flex: 0 0 50%;

                &:nth-child(2) {
                    border-right: none;
                }

                &:nth-child(3), &:last-child {
                    border-top: none;
                }
            }

            @media (max-width: 500px) {
                flex: 0 0 100%;

                &:first-child, &:nth-child(3) {
                    border-right: none;
                }


                &:nth-child(2) {
                    border-top: none;
                }
            }

            &:last-child {
                border-right: none;
            }


            .list-item {
                text-transform: uppercase;
                font-size: 14px;
                line-height: 20px;
                position: relative;
                width: 170px;

                @media (max-width: 500px) {
                    width: 220px;
                }


                &.beacon {
                    &::after {
                        content: "";
                        z-index: -1;
                        width: 10px;
                        height: 10px;
                        box-sizing: border-box;
                        background: none;
                        border: 1px solid rgba($active-nav, 0.3);
                        border-radius: 50%;
                        transition-property: all;
                        transition-duration: .36s;
                        position: absolute;
                        top: 9px;
                        left: -25px;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        &::after {
                            height: 100px;
                            width: 100px;
                            opacity: 1;
                            z-index: 0;
                        }
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    top: 4px;
                    left: -30px;
                    width: 10px;
                    height: 10px;
                    background: transparent;
                    border: 2px solid $active;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
            }
        }
    }

    .hero {
        width: 100%;
        height: 700px;
        overflow: hidden;
        position: relative;

        @media (max-width: 1400px) {
            height: 500px;
        }

        @media (max-width: 567px) {
            height: 300px;
        }

        &__image {
            width: 100%;
            height: 700px;
            background: url(./../assets/images/extrata-hero.jpg) center center;
            background-size: cover;

            @media (max-width: 1400px) {
                height: 500px;
            }
        }
    }



    .block {
        padding: 120px 0 100px 0;
        display: flex;
        justify-content: center;

        @media (max-width: 991px) {
            padding: 30px;
        }

        @media (max-width: 556px) {
            padding: 30px 15px;
        }




        &__container {
            display: flex;
            width: 1320px;

            @media (min-width: 576px) {
                max-width: 100%;
                padding: 0 15px;
            }

            @media (min-width: 768px) {
                max-width: 100%;
                padding: 0 30px;
            }

            @media (min-width: 992px) {
                max-width: 960px;
            }

            @media (max-width: 991px) {
                flex-direction: column;
                align-items: center;
                max-width: 720px;
            }

            @media (min-width: 1200px) {
                max-width: 1140px;
            }

            @media (min-width: 1400px) {
                max-width: 1320px;
            }
        }

        &__aside {
            display: flex;
            flex-direction: column;
            width: 400px;

            @media (max-width: 991px) {
                width: 100%;
            }

            @media (max-width: 567px) {
                padding: 0;
            }
        }

        &__main {
            flex: 1;
        }

        &__component {
            flex: 1;
        }


        &.last {
            padding-bottom: 300px;
        }
    }

    .block.component {
        .row {
            flex-wrap: nowrap;

            @media (max-width: 991px) {
                flex-wrap: wrap;
            }
        }

        .container {
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }



    .section-title {
        .line-decoration {
            width: 100%;
            height: 5px;
            background: $app-primary01;
            display: block;
            margin-bottom: 40px;
        }

        h2 {
            font-size: 40px;
            line-height: 48px;
            font-weight: 300;
            color: $heading;
            max-width: 360px;

            @media (max-width: 991px) {
                max-width: 100%;
            }
        }
    }

    .accreditation {
        display: flex;
        padding: 0 0 0 0;

        @media (max-width: 991px) {
            max-width: 400px;
        }

        &__logo {
            width: 150px;
        }

        &__content {
            display: flex;
            align-items: center;
            flex: 1;
            padding: 0 0 0 20px;

            p {
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;
                margin: 0;
            }
        }
    }


    .section-content {
        padding-left: 150px;

        @media (max-width: 1200px) {
            padding-left: 70px;
        }

        @media (max-width: 991px) {
            padding-left: 0;
            margin-top: 30px;
        }
    }

    .feature {
        display: flex;
        border: solid 1px $border-primary;

        @media (max-width: 880px) {
            flex-direction: column;
        }

        &__block {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 50px;
            border-right: solid 1px $border-primary;

            @media (max-width: 880px) {
                border-bottom: solid 1px $border-primary;
            }

            @media (max-width: 556px) {
                padding: 30px;
            }

            &:last-child {
                border-right: none;

                @media (max-width: 880px) {
                    border-bottom: none;
                }
            }

            &__icon {
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: linear-gradient(180deg, #005C81 0%, #002E40 100%);
                margin-bottom: 20px;

                @media (max-width: 556px) {
                    width: 60px;
                    height: 60px;
                }

                .icon {
                    color: $white;
                    font-size: 30px;
                }
            }

            &__title {
                color: $heading;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 10px;
            }
        }
    }

    section.data-graphic {
        background: $page-bg-dark;
        padding: 60px 0;

        @media (max-width: 991px) {
            padding: 30px 0 50px;
        }

        .headline {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__container {
                margin-top: 70px;
                display: flex;
                flex-direction: column;

                @media (max-width: 1100px) {
                    margin-top: 20px;
                }

                @media (max-width: 991px) {
                    margin-top: 0;
                    align-items: center;
                }

                @media (max-width: 991px) {
                    .external__lead {
                        margin-top: 0;
                    }
                }

                @media (max-width: 767px) {
                    .external__headline .line {
                        height: 40px;

                        h2 {
                            font-size: 34px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }


        .external__headline {
            display: flex;
        }

        .cta {
            margin-top: 30px;
            display: flex;

            .ds-btn {
                width: initial;
            }
        }
    }
}

    #smooth-wrapper {
        background: $white url(./../assets/images/footer-graphic.svg) no-repeat center bottom;
        background-size: 1600px;
    }

.swiper-container {
    width: 65vw;
    display: flex;
    height: 100%;

    @media (max-width: 1400px) {
        width: 62vw;
    }

    @media (max-width: 1200px) {
        width: 56vw;
    }

    @media (max-width: 991px) {
        width: 86vw;
        padding-top: 30px;

        .swiper {
            padding-left: 0;
        }
    }

    @media (max-width: 767px) {
        width: 96vw;
    }

    @media (max-width: 567px) {
        width: 103vw;
        position: relative;
        left: -30px;
        padding-left: 15px;
    }

}


.external__page {
    /*@media (max-width: 1399.8px) {
        #formCard, .external__introduction .cta .ds-btn {
            display: none;
        }
    }*/


    .landing {
        &__notification {
            padding: 0 30px;

            /*@media (min-width: 1399.8px) {
                display: none;
            }*/
        }

        &__image {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;

            img {
                max-width: 200px;
            }
        }

        &__message {
            font-size: 14px;
        }
    }
}


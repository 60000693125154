﻿//Vanilla message i.e. verification email

.full-page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: $page-bg-dark url(./../assets/images/data-pattern.svg) no-repeat center bottom;
    background-size: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .message-container {
        background: $white;
        box-shadow: $tiles-shadow;
        border-radius: $border-radius;
        width: 400px;
        max-width: 400px;
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        margin: 0 20px;

        &__icon, &__title, &__description, &__link {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-bottom: 20px;
        }

        &__icon {
            img {
                width: 68px;
                height: auto;
            }
        }


        &__title {
            color: $heading;
            font-weight: 700;
            font-size: 24px;
        }

        &__description {
            text-align: center;
            color: $text;
            font-weight: 300;
            padding: 0 30px;
        }

        &__link {
            margin-bottom: 0;

            a {
                width: 100%;
                height: 54px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
                font-weight: 500;
                background: $app-primary01;
                text-decoration: none;

                &:hover {
                    background: $hover;
                }
            }
        }
    }
}



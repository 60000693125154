﻿//Colors
$text: #828282;
$non-active: rgba($text, 0.6);
$lead: #4f4f4f;
$black: #000000;
$dark-grey: rgba($black, 0.8);
$header-nav: #2e3a59;
$icons: $header-nav;
$heading: #121212;
$white: rgba(255, 255, 255, 1);
$white-opaque: rgba($white, 0.5);
$headerDesc: #dcede1;
$backdrop: rgba($black, 0.8);
$page-bg-light: #f4f7f9;
$page-bg-dark: #ebeff3;
$app-primary01: #003b69;
$background: rgba($app-primary01, 0.02);
$border-primary: rgba($app-primary01, 0.2);
$orange: #ff7a00;
$off-white: #ababab;
$input: #fcfdfe;
$input-border: #dee1e0;
$required: #d30023;
$delete: #d30023;
$hover: #0d6efd;
$active-nav: #0d6efd;
$active: #0d6efd;
$disabled: #a5b9ce;
$emphasize: #0d6efd;
$hover-grey: #e1e1e1;
$tile: #fafdff;
$footer-bg: #f4f7f9;
$footer-home-bg: #e1e1e1;
$green: #0b9000;
$paused: $page-bg-dark;
$medium-grey: #c6c6c6;
$light-grey: #f2f2f2;
$button-bg: #8A9DB2;

$red: #dc3545;
$red-hover: #b42230;
$accordion-header: #e7f1ff;
$yellow: #fcbb05;
$statsBG: #d3d9df;
$pill-draft: #FCF0C5;
$pill-draft-text: #A37E00;
$pill-public: $page-bg-dark;
$pill-public-text: $heading;
$pill-private: #DEF3FF;
$pill-private-text: #003B69;
$pill-template: #D5FFD1;
$pill-template-text: #006704;






.business, .avatar {
    button.edit-image {
      visibility: hidden;
      position: absolute;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, .5) url(../assets/images/icons/camera-line-icon-white.svg) no-repeat center center;
      background-size: 24px;
    }
    &:hover {
      button.edit-image {
        visibility: visible;
      }
    }
  }
  
  .avatar {
    &:hover {
      button.edit-image {
        visibility: visible;
        border-radius: 50%;
      }
    }
  }
  
  .business {
    display: flex;
    margin-bottom: 30px;
    &__logo {
      position: relative;
      background: $page-bg-dark url(../assets/images/icons/profile-icon.svg) no-repeat center center;
      background-size: 24px;
      box-shadow: $avatar-shadow;
      border: solid 5px $white;
      border-radius: 4px;
      width: 255px;
    }
    &__image {
      background: $page-bg-dark;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      padding: 10px;
    }
  } 
  .avatar-settings {
    display: flex;
    margin-bottom: 30px;
    .avatar {
      position: relative;
      background: $page-bg-dark url(../assets/images/icons/profile-icon.svg) no-repeat center center;
      background-size: 38px;
      box-shadow: $avatar-shadow;
      border: solid 5px $white;
      border-radius: 50%;
      width: 130px;
      height: 130px;
      &__image {
        top: 0;
        left:0;
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 50%;
      }
    }
    &__details {
      display: flex;
      flex-direction: column;
      padding-left: 25px;
      justify-content: center;
    }
    &__name {
      font-size: 24px;
      font-weight: 700;
      color: $heading;
    }
    &__organisation {
      font-size: 16px;
      font-weight: 500;
      color: $text;
    }
    &__organisation-name {
      font-size: 24px;
      font-weight: 700;
      color: $heading;
    }
    
  }
  
  

.settings-form, .account-form, .password-form, .preferences-form {
    .controls {
      margin-top: 30px;
      justify-content: flex-end;
  
    }
  }
  
  .role-settings, .notification-settings {
    .form-group {
      display: flex;
      .control {
        width: initial;
        padding-right: 30px;
      }
    }
  
  }
  
  .two-factor-seetings {
    .form-group {
      display: flex;
      align-items: center;
      .authentication-status {
        padding: 0 0 0 30px;
      }
      .toggle {
        position: relative;
        top: 6px;
        left: 10px;
      }
    }
  
  }
   
  
  .terms {
    padding: 15px 30px;
    border-radius: 10px;
    border: solid 1px $border-primary;
    
  }
  
  .time-stamp {
    margin-top: 10px;
    span {
      color: $delete;
      font-weight: 500;
    }
  }
  
  
  .newsletter-settings {
    .control {
      margin-bottom: 15px;
    }
  }

.api-key {
    margin-bottom: 15px;

    .form-control{
        height: 44px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: $input;
        border: solid 1px $input-border;
        border-radius: 4px;
        color: $text;
        font-weight: 300;
    }
}


.policy {
    padding-bottom: 20px;

    &__block {
        padding: 15px 30px;
        border-radius: 10px;
        border: solid 1px $border-primary;
        ul {
            padding-left: 0;
            margin: 0;
        }
    }
}


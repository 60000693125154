h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading;
}

.lead {
    font-size: 12px;
    line-height: 16px;
    color: $lead;
}
﻿//Configure product wizard

.bundle-config {
    width: 100vw;
    height: 100vh;
    padding: 15px;
    overflow: hidden;

    &__layout {
        display: flex;
        position: relative;
        height: calc(100% - 60px);

        .col {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-right: 10px;
            background: $white;
            overflow: hidden;
            font-size: 14px;
            line-height: 18px;
            border-radius: 10px;
            border: solid 1px $border-primary;


            &:last-child {
                margin-right: 0;
            }

            &__inner {
                position: relative;
                display: flex;
                flex-direction: column;
                flex: 1;
                background: $white;
                overflow: auto;
                -webkit-overflow-scrolling: touch;

                .no-bundle {
                    width: 300px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;

                    padding {
                        line-height: 1.4em;
                    }

                    img {
                        width: 200px;
                        height: auto;
                        margin-bottom: 10px;
                    }
                }
            }

            &__heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 7px 15px 15px;
                border-bottom: solid 1px $border-primary;
            }

            &__title {
                font-size: 20px;
                font-weight: 500;
                color: $heading;
            }

            &__controls {
                display: flex;
                align-items: center;

                button.ds-btn.text {
                    color: $emphasize;
                    padding: 0 8px;
                    display: flex;

                    &.delete {
                        color: $red;
                    }

                    .icon {
                        position: relative;
                        top: 2px;
                        
                    }

                    &:focus {
                        background: transparent;
                        color: $emphasize;
                        border: transparent;
                    }
                }
            }

            &__list {
                padding: 15px;
                display: flex;
                border-bottom: solid 1px $border-primary;

                &:hover {
                    background: $page-bg-dark;

                    .col__list__heading .bundle__control button {
                        &:focus {
                            background: transparent;
                            color: $app-primary01;
                            border: transparent;
                        }

                        .icon {
                            display: flex;
                        }
                    }
                }

                &.is-selected {
                    background: $app-primary01;

                    .col__list__heading {
                        color: $white;
                    }

                    .col__list__description, .col__list__stats .users {
                        color: rgba($white, 0.5);
                    }

                    .col__list__control button.ds-btn.text.disabled, .col__list__control button.ds-btn.text {
                        background: transparent;
                        border: solid 1px rgba($white, 0.5);

                        &.authentication {
                            border: solid 1px rgba($red, 1.0);
                        }
                    }

                    .col__list__stats .users__number {
                        background: transparent;
                        color: $white;
                        border: solid 1px rgba($white, 0.5);
                    }

                    .col__list__heading .bundle__control button {
                        color: $white;

                        &:focus {
                            color: $emphasize;
                        }

                        &:hover {
                            color: $emphasize;
                        }
                    }
                }

                &.batch-select {
                    .col__list__stats {
                        padding: 0 0 0 32px;
                    }
                }


                &__content {
                    display: flex;
                    flex-direction: row;
                    padding: 0;
                    flex: 1;


                    &__inner {
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        .col__list__heading {
                            padding-bottom: 10px;
                            display: flex;
                            justify-content: initial;
                            position: relative;

                            .bundle__name {
                                max-width: calc(100% - 160px);
                                display: block;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .bundle__controls {
                                position: absolute;
                                right: 0;
                                width: 150px;
                                display: flex;
                                justify-content: flex-end;


                                button.ds-btn.text {
                                    @include flex-row-centered;
                                    background: $white;
                                    color: $app-primary01;
                                    height: initial;
                                    padding: 0;
                                    width: 26px;
                                    height: 26px;
                                    border: solid 1px $border-primary;
                                    border-radius: 50%;
                                    margin-left: 5px;

                                    &:first-child {
                                        margin-left: 0;
                                    }

                                    .icon {
                                        position: relative;
                                        top: 2px;
                                    }

                                    &:hover {
                                        background: $hover;
                                        color: $white;
                                        border: solid 1px $hover;
                                    }
                                }
                            }
                        }

                        .col__list__description {
                            word-break: break-word;
                        }
                    }
                }

                &__status {
                    width: 30px;
                    display: flex;
                    align-items: center;

                    .pill {
                        height: 100%;
                        width: 16px;
                        padding: 0;

                        span {
                            transform: rotate(-90deg);
                        }
                    }
                }

                &__footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    &__labels {
                        display: flex;
                        justify-content: flex-end;


                        .bundle__status {
                            @include flex-row-centered;
                            height: 28px;
                            min-width: 25px;
                            border-radius: 15px;
                            background: $button-bg;
                            padding: 0 2px;

                            .icon {
                                font-size: 14px;
                                color: $white;
                                padding: 0 5px 0 5px;
                                position: relative;
                                top: 2px;
                            }
                        }
                    }
                }

                &__heading {
                    font-weight: 500;
                    color: $heading;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 5px;

                    .bundle__control {
                        display: flex;

                        button {
                            font-size: 16px;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:focus {
                                background: transparent;
                                color: $app-primary01;
                                border: transparent;
                            }
                        }

                        .icon {
                            display: none;
                        }

                        .pill {
                            margin-left: 8px;

                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }

                &__stats {
                    display: flex;
                    margin-top: 5px;

                    .users {
                        display: flex;
                        align-items: center;
                        margin-right: 20px;

                        &__number {
                            font-weight: 700;
                            color: $heading;
                            background: $statsBG;
                            padding: 3px 10px;
                            border-radius: 30px;
                            margin-right: 8px;
                        }
                    }
                }




                &__select {
                    display: flex;
                    width: 32px;
                    align-items: center;
                    margin-top: 10px;

                    .form-group {
                        position: relative;
                        top: -15px;

                        .checkbox__label::before {
                            -webkit-border-radius: 50%;
                            border-radius: 50%;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &__footer {
        @include flex-row-centered;
        padding: 15px;
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, 100px, 0);
        bottom: 25px;
        z-index: 6;
        border-radius: 16px;
        @include blurredBG;
        transition: transform 0.3s ease-in-out;

        &.active {
            transform: translate3d(-50%, 0, 0);
        }

        &__controls {
            span.bundle-name {
                font-weight: 700;
                color: $heading;
                margin-right: 5px;
            }


            .controls {
                margin: 10px 0 0 0;
                display: flex;
                position: relative;

                .form-group {
                    margin-left: 20px;
                }
            }
        }


        .controls {
            margin-top: 0;
            display: flex;

            .button {
                width: 158px;
                margin: 0 5px;
            }
        }
    }

    &__cancel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ds-btn.text {
            width: initial;
            color: $text;
            font-size: 24px;
        }
    }

}


.column__controls {
    position: absolute;
    bottom: 5px;
    right: 10px;
    width: 50px;
    height: 50px;
    z-index: 5;


    button.ds-btn.text {
        width: 40px;
        height: 40px;
        @include flex-row-centered;
        border-radius: 50%;
        background: $emphasize;
        box-shadow: $control-button;
        color: $white;

        &.delete {
            background: $red;
        }



        .icon {
            position: relative;
            top: 2px;
            font-size: 20px;


            &.icon-close-icon {
                transform: rotate(45deg);
            }
        }
    }
}

.panel-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0,0,0,0.6);
}

.panel {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 10;
    width: 600px;
    max-width: 600px;
    background: $page-bg-light;
    box-shadow: $panel;

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-bottom: solid 1px $border-primary;
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
        color: $heading;
    }



    &__content {
        position: absolute;
        width: 100%;
        height: calc(100% - 120px);
        padding: 30px 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .accordion {
            padding: 0 30px;

            .accordion-header {
                border: solid 1px $border-primary;

                &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    overflow: hidden;
                }
            }

            .accordion-button {
                margin: 0;
            }

            .add-parameter {
                button {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }

    .form-description {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 18px;
    }

    .form-break {
        padding: 0 30px;
    }

    .logo-control {
        margin-top: 0;
        flex-wrap: wrap;
        padding: 0 30px;

        .controls {
            display: flex;
            margin: 0 0 20px;
            display: flex;
            flex-direction: column;



            .form-group {
                background: $input;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 100px;
                border: solid 1px rgba(0, 59, 105, 0.2);
                border-radius: 4px;
                display: flex;
                padding: 10px;
                margin: 0 0 10px;

                span.label {
                    position: absolute;
                    top: calc(50% - 7px)
                }

                .upload-banner, .upload-logo, .business-name {
                    width: 400px;
                    position: relative;
                    left: 30px;
                    color: $heading;
                    line-height: 18px;
                    margin-top: 0;
                }

                .business-name {
                    font-weight: 500;
                }
            }
        }
    }

    .config {
        padding: 0 30px;

        &__options {
            .controls {
                display: flex;
                justify-content: center;
                align-items: initial;
                margin-top: 20px;
                position: initial;
                bottom: initial;
                width: initial;
                height: initial;



                button {
                    height: auto;
                    padding: 10px 0;
                    margin: 0 10px 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    &:last-child {
                        margin-right: 0;
                    }

                    .icon {
                        font-size: 50px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .auto-setup {
        //border-top: solid 1px $border-primary;
        //border-bottom: solid 1px $border-primary;
        //margin-top: 30px;
        padding: 0 30px;
    }

    .manual-setup, .data-sources {
        padding: 30px;
    }

    .manual-setup {
        padding-top: 0;

        .form-group #dsUrl {
            padding-right: 83px;
        }


        .form-group.inline {
            display: flex;


            label {
                display: flex;
                width: initial;
                line-height: 1.3em;
                margin-right: 5px;
                font-weight: 500;
                color: $heading;
                white-space: nowrap;
            }

            p {
                display: flex;
                line-height: 1.3em;
                margin: 0;
                font-size: 14px;
            }

            #dsUrl {
                padding-right: 83px;
            }
        }
    }


    .data-sources {
        .controls {
            margin: 0;
            display: flex;
            justify-content: space-between;

            .button {
                button {
                    color: $emphasize;
                }

                .icon {
                    position: relative;
                    top: 2px;
                }
            }
        }


        &__select {
            position: relative;
            top: -3px;
        }

        &__item {
            display: flex;
            align-items: center;
            background: $white;
            border-radius: 4px;
            padding: 15px;
            border: solid 1px $border-primary;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            .checkbox__label::before {
                border-radius: 50%;
            }
        }
    }


    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 30px;
        border-top: solid 1px $border-primary;
        background: $page-bg-light;
        height: 64px;
        width: 100%;
        display: flex;
        align-items: center;
        z-index: 9;

        .controls {
            margin: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .button {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}



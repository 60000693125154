﻿

.logged-in {
    .datepicker {
        display: inline-block;
        border-radius: 10px;
        background-color: $white;
        width: 325px;
        box-shadow: $dropdown-shadow;

        .datepicker-days,
        .datepicker-months,
        .datepicker-years {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: calc(100% + 20px);
                height: 1px;
                background: $border-primary;
                left: -10px;
                top: 40px;
            }
        }

        &.datepicker-dropdown {
            padding: 10px;
        }

        .table-condensed {
            width: 100%;
            position: relative;
        }

       
    }

    .datepicker table tr td.day:hover,
    .datepicker table tr td.focused {
        background: $emphasize;
        color: $white;
        cursor: pointer;
    }

    .datepicker table tr td.day.disabled:hover {
        color: $black;
        background-color: rgba($app-primary01, 0.2);
        border-color: rgba($app-primary01, 0.4);
    }

    .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:active {
        background: rgba($app-primary01, 0.2);
        color: $black;
    }

    .datepicker-switch {
        text-align: center;
    }

    .datepicker table tr td {
        height: 42px;
        border-radius: 50%;
        text-align: center;

        &.today {
            color: $black;
            background-color: rgba($app-primary01, 0.2);
            border-color: rgba($app-primary01, 0.4);
        }
    }

    .datepicker .datepicker-switch:hover,
    .datepicker tfoot tr th:hover {
        background: $emphasize;
        color: $white;
    }

    thead tr:nth-child(2) {
        position: relative;
        height: 40px;
        width: calc(100% + 20px);
    }

    thead tr:nth-child(3) {
        padding-top: 10px;
        height: 50px;
    }

    .day {
        color: $text;
    }

    .new.day,
    .year.old,
    .year.new,
    .decade.old,
    .decade.new {
        color: #dadada;
    }

    .datepicker .prev,
    .datepicker .next {
        overflow: hidden;
        text-indent: -999px;
        position: relative;
    }

    .datepicker .prev {
        background: url(../assets/images/icons/chevron-icon-left.svg) no-repeat center center;
        background-size: 9px;
        border-radius: 4px;

        &:hover {
            background: $emphasize url(../assets/images/icons/chevron-icon-left-white.svg) no-repeat center center;
            background-size: 9px;
        }
    }

    .datepicker .next {
        background: url(../assets/images/icons/chevron-icon-right.svg) no-repeat center center;
        background-size: 9px;
        border-radius: 4px;

        &:hover {
            background: $emphasize url(../assets/images/icons/chevron-icon-right-white.svg) no-repeat center center;
            background-size: 9px;
            border-radius: 4px;
        }
    }

    .datepicker table tr td.active.active {
        background: $emphasize;
        border-color: $emphasize;
    }

    .datepicker table tr td span:hover,
    .datepicker table tr td span.focused {
        background: $emphasize;
        color: $white;
    }

    .was-validated .form-control:invalid,
    .was-validated .form-control:valid {
        background-position: right 6px center !important;
    }
}




.logged-in .datepicker table.table-condensed tr td {
    height: 45px;
    width: 53px;
    border-radius: 50%;
    text-align: center;
}

.logged-in .datepicker table tr td.today {
    background: $emphasize;
    color: $white;
}
﻿//Tabs

.tabs-container {
    position: relative;
    margin-top: 30px;

    &__controls {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
       

        .toggle {
            display: flex;
            align-items: center;

            label {
                margin-right: 13px;
            }
        }
    }

    .nav-tabs .nav-link:hover {
        border-color: transparent;
        color: $emphasize;
    }

    .nav-tabs .nav-link.active {
        color: $heading;

        &:hover {
            border-color: #dee2e6 #dee2e6 #fff;
        }
    }

    .nav-item.add {
        display: flex;
    }

    .nav-link {
        display: block;
        padding: 8px 25px;
        color: $non-active;
    }
}

.tab-pane {
    padding: 30px 30px 0 30px;

    .btn-text.select {
        position: absolute;
        right: 10px;
        top: 5px;
        padding: 0;
        margin: 0;
        width: initial;
    }

    .inner-pane {
        border: solid 1px $border-primary;
        border-radius: 10px;
        padding: 30px;
        position: relative;
        .data-source-btn {
            display: none;
        }
        &.add-data-source {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 400px;
            .data-source-btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .button a.ds-btn.outline {
                    color: $heading;
                    font-weight: 500;
                }
            }
        }
        .introduction {
            max-width: 620px;
        }
        .form-container {
            margin: 0;
            padding: 0;
            min-height: initial;
            .form-group {
                max-width: 400px;
            }
        }
    }
}

.tab-content,
.tabs-footer {
    background: $white;
}

.tab-content {
    border-radius: 0 0 4px 4px;
    border: solid 1px $border-primary;
    border-top: none;
}

.tabs-footer,
.modal-footer {
    display: flex;
    justify-content: center;
    padding: 30px 0 40px;
    border-radius: 0 0 4px 4px;

    .tabs-control,
    .modal-control {
        display: flex;
        justify-content: space-between;
        width: 300px;

        .button {
            flex: 1;
            padding-right: 5px;

            &:last-child {
                padding: 0 0 0 5px;
            }
        }
    }

    .tabs-control.single {
        display: flex;
        justify-content: center;
        width: 100%;
        .button {
            display: flex;
            justify-content: center;

            button.ds-btn {
                max-width: 150px;
            }
        }
    }
}

.modal-footer {
    padding: 10px;
    border-top: none;
    .modal-control {
        width: 100%;
        .button {
            &:last-child {
                padding: 0;
            }
        }
    }
}

#db,
#add-data-source,
#add-parameters,
#authentication {
    input[type="password"] {
        background-image: none;
    }
    input,
    textarea {
        background-image: url(./../assets/images/icons/edit-icon-blue.svg);
        background-repeat: no-repeat;
        background-position: right 10px top 14px;
        background-size: 14px;
    }
}
// edit-icon-blue.svg

.logo-control {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;

    .label {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .controls {
        width: 660px;
        max-width: 660px;

        .form-group {
            width: 315px;
            max-width: 315px;
            border: solid 1px $border-primary;
            border-radius: 4px;
            display: flex;
            padding: 10px;

            .control {
                margin-bottom: 0;
                color: $heading;
                font-weight: 500;
                line-height: 18px;
            }



            .checkbox {
                align-items: initial;
            }

            .upload-logo, .upload-banner,
            .business-name {
                display: flex;
                flex-direction: column;
                max-width: 250px;
                height: 55px;
                justify-content: center;
                align-items: center;

                .icon {
                    color: $emphasize;
                }
            }

            .upload-logo, .upload-banner {
                font-weight: 300;
                border: dashed 1px $border-primary;
                background: $page-bg-dark;
                border-radius: 4px;
            }

            

            .business-name {
                height: 38px;
                margin-top: 9px;
                justify-content: center;
                align-items: center;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }

    .control_indicator {
        border-radius: 50%;
        margin-top: 16px;
    }
}

.bundle-privacy {
    background: $page-bg-dark;
    border-radius: 10px;
    padding: 30px;
    .toggle {
        display: flex;
        align-items: center;
        padding: 0 0 0 10px;
        margin-bottom: 30px;
        &__label {
            padding: 0 0 0 15px;
            font-weight: 500;
        }
    }
}

.share {
    .form-control[readonly] {
        background-color: $white;
    }
    button.share-button {
        position: absolute;
        right: 10px;
        top: 40px;
        z-index: 99;
        border: none;
        background: transparent;
        color: $emphasize;
        font-size: 18px;
    }
    .form-label {
        position: relative;
        &:before {
            content: "URL";
            position: absolute;
            top: 40px;
            left: 10px;
            color: $active;
            z-index: 99;
            font-weight: 500;
        }
    }
}
input.share-link {
    background-image: none !important;
    padding: 0 40px 0 40px !important;
}

.page.request {
    #productAnimInformationModal {
        overflow: hidden;

        .toggle {
            margin-top: 11px;
        }
    }

    .tabs-container {
        height: calc(100% + 30px);
        margin-top: 0;
    }

    .tab-content,
    .tab-pane {
        height: calc(100% - 42px);
        position: relative;
        border-radius: 0;
    }

    form {
        height: 100%;
    }

    .tab-pane {
        height: 100%;
        padding: 0;
    }

    .tab-pane .inner-pane {
        border: none;
        border-radius: 0;
        padding: 0;
        position: relative;
        height: calc(100% - 104px);
    }

    .modal-body {
        height: 100%;
        overflow-y: auto;
        padding: 20px;
        border-right: solid 1px rgba(0, 59, 105, 0.2);
        border-bottom: solid 1px rgba(0, 59, 105, 0.2);
        border-left: solid 1px rgba(0, 59, 105, 0.2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .modal-footer {
        padding: 10px;
        border-top: none;
        position: absolute;
        bottom: -88px;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        button {
            width: 180px;
        }
    }
}

#productAnimInformationModal {
    .tab-content {
        border: none;
        overflow: hidden;
    }
}
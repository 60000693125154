﻿//Configure product wizard



.wizard-container {
    display: flex;

    &.draft, &.published {
        .wizard__status__right {
            display: flex;
        }
    }

    &.draft {
        span.draft {
            display: flex;
        }

        span.published {
            display: none;
        }
    }

    &.published {

        span.draft {
            display: none;
        }

        span.published {
            display: flex;
        }
    }
}



    .wizard {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        width: 100%;

        &:hover {
            .wizard__button {
                background: $hover;
            }
        }

        &.complete {
            .wizard__status__step {
                display: none;
            }

            .wizard__status__complete {
                display: flex!important;
            }

            .wizard__button {
                background: $green;
            }

            .wizard__edit {
                display: flex;
            }

            .wizard__button {

                span.edit {
                    display: none;
                }

                span.complete {
                    display: flex;
                }
            }

            .wizard__status__right {
                display: flex;
            }
        }

        &__status {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            &__right {
                span.draft {
                    color: $orange;
                }

                span.published {
                    color: $green;
                }

                font-weight: 500;
                display: none;
            }




            &__complete, &__step {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__complete {
                display: none;

                img {
                    width: 50px;
                    height: 50px;
                }
            }

            &__step {
                border: dashed 2px $input-border;
                font-size: 20px;
                font-weight: 700;
                color: $emphasize;
            }
        }

        &__title {
            font-size: 24px;
            font-weight: 700;
            color: $heading;
            margin-bottom: 10px;
        }

        &__description {
            ul {
                padding-left: 20px;
                font-size: 14px;
                line-height: 16px;

                li {
                    margin-bottom: 5px;
                }
            }
        }

        &__edit {
            color: $green;
            font-weight: 500;
            display: none;
        }

        &__button {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 44px;
            background: $app-primary01;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-size: 16px;
            font-weight: 500;
            border-radius: 4px;

            span.edit {
                display: flex;
            }

            span.complete {
                display: none;
            }
        }
    }


.page .inner-content {
    .bundle-process {
        position: relative;
        width: 100%;
        height: 100%;
        background: $white;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &__cancel {
            position: absolute;
            right: 0;
            top: 0;
            color: $emphasize;
            z-index: 8;
        }

        &__new-collection {
            position: absolute;
            right: 0;
            top: 44px;
            color: $emphasize;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .btn-group {
                button {
                }

                .dropdown-menu {
                    border: none;
                    border-radius: 10px;
                    box-shadow: $dropdown-shadow;
                    width: 225px;
                    top: -10px !important;

                    .ds-btn.text {
                        color: $heading;
                        text-align: center;
                        width: 100%;
                        font-weight: 500;

                        &::after {
                            content: '';
                            display: none;
                        }
                    }
                }
            }
        }



        .content-wrapper {
            display: flex;
            position: relative;
            height: calc(100% - 100px);
        }


        .main-col {
            padding-right: 75px;
            position: relative;
            height: 100%;
            flex: 1;

            &__main {
                position: relative;
                height: calc(100% - 100px);

                &.border {
                    border: solid 1px $border-primary;
                    padding: 30px;
                    border-radius: 10px;
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                }

                .logo-control {
                    margin-top: 0;
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: column;

                    .controls {
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        position: initial;
                        bottom: initial;
                        width: initial;
                        height: initial;
                        display: flex;
                        justify-content: initial;
                        align-items: initial;
                    }
                }

                .text-break {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    font-size: 18px;
                    color: $heading;
                    font-weight: 700;
                    padding: 0 0 10px;
                }
            }
        }

        .side-col {
            width: 400px;
            height: calc(100vh - 280px);
            background: $white;
            display: flex;
            padding: 30px;
            justify-content: center;
            align-items: center;

            &__help {
                width: 100%;
                height: auto;
            }
        }

        .process-description {
            display: flex;
            flex-direction: column;
        }

        .tab-content {
            height: calc(100% - 30px);
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        .tabs-container, form {
            height: calc(100% - 10px);
        }


        .tabs-container, .tab-content {
            position: relative;
            margin-top: 0;

            .tab-pane {
                padding-bottom: 50px;
            }
        }

        .accordion {
            .add-parameter {
                margin-top: 5px;
            }

            .accordion-button {
                margin: 0;
            }
        }





        .custom-select-wrapper {
            .custom-options {
                top: 100%;
                width: 100%;
            }

            .custom-select__trigger {
                justify-content: flex-start;

                span {
                    font-weight: 500;

                    &::before {
                        content: '';
                        margin-right: initial;
                        color: #4f4f4f;
                        font-weight: 500;
                    }
                }
            }
        }

        &__footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;


            .row {
                margin: 0;
            }

            .controls {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 0;
                width: 100%;
                height: 100px;

                .button {
                    button {
                        width: initial;
                    }
                }

                .bundle-status {
                    .controls {
                        .form-group {
                            margin-right: 25px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }





                    .configure-progress {
                        sup {
                            color: $black;
                            font-weight: 700;
                        }

                        .step-name {
                            padding-left: 30px;
                            position: relative;
                            font-weight: 500;
                            color: $heading;

                            &:before {
                                content: '';
                                position: absolute;
                                width: 20px;
                                height: 1px;
                                display: block;
                                background: $black;
                                left: 5px;
                                top: 8px;
                            }
                        }
                    }
                }

                .actions {
                    display: flex;

                    .button {
                        margin-left: 10px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .summary {
            display: flex;
            flex-direction: column;
            position: relative;
            height: calc(100% - 100px);

            &__bundle {
                position: relative;
                margin: 0 30px;
                border: solid 1px $border-primary;
                border-radius: 10px;
                height: 100%;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
            }

            &__content {
                padding: 30px;
            }

            &__logo {

                &__container {
                    width: 200px;
                    height: 55px;
                    margin-bottom: 20px;

                    img {
                        max-width: 200px;
                        max-height: 110px;
                    }
                }
            }

            &__details {
                display: flex;
                align-items: center;
            }

            &__title {
                padding: 10px 0;
                font-size: 24px;
                line-height: 32px;
                color: $heading;
                font-weight: 500;
            }

            &__datasources {
                padding: 0 10px;
                display: flex;
                align-items: center;
                position: relative;
                top: 2px;
            }

            &__description {
                max-width: 600px;
                padding: 15px 0;
            }

            &__collections {
                display: flex;
                flex-direction: column;
                border-top: solid 1px $border-primary;
                padding: 30px 0 0 0;


                &__title {
                    font-size: 20px;
                    line-height: 28px;
                    color: $heading;
                    font-weight: 500;
                    padding-bottom: 15px;
                }

                &__datasource {
                    padding: 10px 15px;
                    background: $page-bg-light;
                    margin-bottom: 2px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .config {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;

            &__inner {
                width: 500px;
                max-width: 500px;

                .process-description {
                    &__title, &__intro {
                        text-align: center;
                    }
                }
            }

            &__options {
                .controls {
                    display: flex;
                    justify-content: center;
                    align-items: initial;
                    margin-top: 20px;
                    position: initial;
                    bottom: initial;
                    width: initial;
                    height: initial;



                    button {
                        height: auto;
                        padding: 10px 0;
                        margin: 0 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        .icon {
                            font-size: 50px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .data-sources {

            .controls {
                margin-top: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                position: initial;
                bottom: initial;
                width: initial;
                height: initial;

                .form-group {
                    padding: 5px 10px;
                    background: $page-bg-light;
                    margin-bottom: 2px;
                    width: 100%;
                }
            }
        }

        .auto-setup {
            &__options {
                border-radius: 4px;
                padding: 15px;
                background: $page-bg-light;
            }
        }


        .success {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: $white;
            z-index: 3;
            display: flex;
            justify-content: center;
            align-items: center;

            &__inner {
                width: 500px;
                max-width: 500px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__icon {
                width: 100px;
                height: 100px;
            }

            &__title {
                text-align: center;
                font-size: 24px;
                font-weight: 500;
                color: $heading;
                line-height: 32px;
                padding: 20px 0;
            }

            &__message {
                margin-top: 50px;

                p {
                    margin-bottom: 5px;
                }

                .controls {
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .button {
                        margin: 0 5px;
                    }
                }
            }
        }
    }
}


.wizard__status__complete {
    display: none;
    }
﻿//layout
//login

.login__title,
.form__title {
    h4 {
        text-align: left;
        font-size: 24px;
        font-weight: 700;
        color: $heading;
        margin-bottom: 20px;
    }
}


.create-account {
    display: flex;
    margin: 0 0 20px 0;
    font-size: 14px;
    line-height: 20px;

    a {
        margin-left: 4px;
        cursor: pointer;
        font-weight: 500;
    }
}

.register {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    background: $white;

    &__form {
        width: 50%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        flex-direction: column;
        opacity: 0;

        @media (max-width: 1200px) {
            width: 100%;
        }

        .form-elements {
            width: 400px;
            max-width: 400px;
            @media (max-width: 500px) {
                width: 300px;
                max-width: 300px;
                margin: auto;
            }

                .view-password {
                    top: 31px;
                }
            }
        }

        &__carousel {
            position: relative;
            width: 50%;
            height: 100vh;
            overflow: hidden;

            @media (max-width: 1200px) {
                display: none;
            }



            .feature__image {
                position: relative;
                width: 100%;
                height: 100%;
                background: url(../assets/images/iot.png) top right;
                background-size: cover;
                z-index: 1;
            }

            .tint {
                position: absolute;
                width: 100%;
                height: 100vh;
                background-color: rgba($app-primary01, 0.5);
                z-index: 2;
            }

            .tagline {
                width: 760px;
                max-width: 760px;
                height: 140px;
                position: absolute;
                top: 50%;
                left: 100px;
                z-index: 999;
            }

            .carousel {
                position: absolute;
                bottom: 0;
                width: 100%;
                min-height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $app-primary01;
                z-index: 3;


                &__title {
                    position: absolute;
                    top: 30px;
                    left: 30px;
                    color: $white;
                }

                .swiper {
                    width: 100%;
                    min-height: 200px;
                    padding: 0 20px 30px;
                    position: relative;
                    top: 0;

                    .swiper-pagination-bullets {
                        bottom: 10px;
                    }
                }

                .swiper-slide {
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    top: 40px;
                    color: $white;

                    .swiper-inner {
                        h5 {
                            color: rgba($white, 0.8);
                            font-size: 16px;
                            max-width: 630px;
                            margin-bottom: 15px;
                        }

                        p {
                            color: $off-white;
                            padding-bottom: 20px;
                        }

                        .badges {
                            display: flex;
                            justify-content: center;
                            margin-top: 25px;

                            .app-store {
                                padding: 0 15px;
                            }
                        }
                    }
                }

                .swiper-pagination-bullet-active {
                    background: $white;
                }
            }
        }
    }

.ds-page {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100%;
    overflow: hidden;

    &.no-inner-side-menu {
        .ds-page__content {
            border-radius: 0;
        }
    }

    &.ds-fixed-footer {
        display: block;
        height: calc(100% - 60px);
    }

    &__navigation {
        position: relative;
        width: 300px;
        padding: 30px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .category-nav {
            color: $lead;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 5px;
        }

        .ds-btn.config {
            position: fixed;
            bottom: 30px;
            width: 240px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
                position: relative;
                top: 4px;

                &.icon-settings-icon {
                    padding-right: 7px;
                    font-size: 20px;
                }

                &.icon-arrow-drop-right-icon {
                    padding-left: 7px;
                }
            }
        }
    }

    &__content {
        position: relative;
        flex: 1;
        background: $page-bg-dark;
        padding: 40px 30px 0;
        overflow-y: auto;
        overflow-x: hidden;

        &__inner {
            position: relative;
            width: 100%;
            min-height: calc(100vh - 185px);
        }

        footer {
            background: transparent;
            margin-top: 20px;
        }

        &.no-radius {
            border-top-left-radius: 0;
        }
    }

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;
    }

    &__title {
        font-size: 24px;
        color: $heading;
        display: flex;
        align-items: center;
        font-weight: 400;

        .user-category {
            margin-left: 10px;
            font-size: 14px;
        }
    }

    &__search {
        position: absolute;
        left: 50%;
        width: 400px;
        transform: translateX(-50%);

        input.product-search {
            background: $input url(./../assets/images/icons/search-icon.svg) no-repeat 10px center;
            background-size: 24px;
            padding: 0 110px 0 40px;
        }

        .submit-btn {
            position: absolute;
            right: 8px;
            top: 8px;
            height: calc(100% - 16px);
            background: $app-primary01;
            border: none;
            color: $white;
            border-radius: 4px;
            font-size: 14px;
            display: flex;
            width: 38px;
            justify-content: center;
            align-items: center;

            .icon {
                position: relative;
                top: 2px;
            }
        }
    }

    &__icon {
        width: 40px;
        height: 40px;
        background: $app-primary01;
        border-radius: 50%;
        color: $white;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;

        .icon {
            position: relative;
            top: 2px;
        }
    }

    &__mini-icon {
        width: 35px;
        height: 35px;
        background: $app-primary01;
        border-radius: 50%;
        color: $white;
        margin-right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }
}

    .page-tabs {
        &.horizontal {
            display: flex;

            .tab {
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 44px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    padding: 0 20px;
                    color: $text;

                    &.active {
                        background: $white;
                        font-weight: 400;
                        color: $heading;
                        box-shadow: $button-shadow;
                    }
                }
            }
        }
    }

    #formCard {
        position: relative;
        z-index: 1;
        perspective: 600px;
        height: 525px;
        overflow: hidden;
    }

    .formFront {
        padding: 0 30px;
        background-color: $white;
        width: 100%;
        height: 100%;
        z-index: 900;
        transform: rotateX(0deg) rotateY(0deg);
        transition: all 0.4s ease-in-out;
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }

    #formCard.flip .formFront {
        z-index: 900;
        transform: rotateY(180deg);
    }

    .formBack {
        display: none;
        z-index: 800;
        margin-top: -525px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0 30px;
        text-align: center;
        background-color: $white;
        transform: rotateY(-180deg);
        transition: all 0.4s ease-in-out;
        transform-style: preserve-3d;
        backface-visibility: hidden;

        .controls {
            margin-top: 20px;

            .forgot-password {
                color: $active-nav;
                font-weight: 500;
            }
        }

        .form-elements {
            .message {
                p {
                    padding: 0;
                    text-align: left;
                }
            }
        }

        .message {
            margin: 0 0 20px;

            p {
                padding: 0 20px;
            }

            .login__title {
                h4 {
                    text-align: center;
                }
            }
        }
    }

    #formCard.flip .formBack {
        z-index: 1000;
        transform: rotateX(0deg) rotateY(0deg);
    }


    .create-organsation {
        display: flex;
        flex-direction: column;




        &__control {
            display: flex;

            button.ds-btn {
                width: initial;
            }
        }
    }